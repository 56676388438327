<template>
  <component-layout title="Project and Boards">
    <template #menu>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              ADD
            </v-list-item></router-link
          > -->
        </v-list>
        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              EDIT
            </v-list-item></router-link
          > -->
        </v-list>
        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              DELETE
            </v-list-item></router-link
          > -->
        </v-list>
      </v-menu>
    </template>
    <v-row class="d-flex align-center mb-4 px-3">
      <!-- <p class="font-weight-bold p-0 mr-3 pt-3"><u>PROJECT</u></p> -->
      <v-select
        v-bind="basic"
        class="mr-3"
        style="width: 50px"
        :items="filterCriteriaList"
        label="Filter Project By"
        v-model="filterCriteria.status"
      ></v-select>
      <v-text-field
        v-bind="basic"
        style="width: 50px"
        label="Search"
        append-icon="search"
        value=""
        outlined
        v-model="filterCriteria.q"
      ></v-text-field>
    </v-row>
    <v-treeview
      v-if="projects.length > 0"
      v-model="tree"
      :open="initiallyOpen"
      :items="computedItems"
      activatable
      item-key="id"
      open-on-click
      dense
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="'parent_id' in item && item.parent_id"
          >mdi-calendar-check-outline</v-icon
        >
        <v-icon v-else> mdi-{{ item.icon }} </v-icon>
      </template>

      <template #label="{ item }">
        <div>{{ item.name }}</div>
      </template>
      <template #append="{ item }">
        {{ item.id }}
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                @click="itemClicked(item, lItem)"
                dense
                v-for="(lItem, i) in item.context_action"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon color="black">{{ lItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="lItem.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-treeview>

    <div class="row">
      <div class="col-md-12">
        <v-pagination
          :length="filterCriteria.length"
          v-model="filterCriteria.page"
          @input="paginationClicked"
        ></v-pagination>
      </div>
    </div>
    <v-dialog v-model="showEditForm" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <CreateProject :edit="clickedItem" />
          </div>
        </div>
      </div>
    </v-dialog>
  </component-layout>
</template>

<script>
import util from "@/util/util";
import ComponentLayout from "../component-layout.vue";
// import popUpLayout from "@/components/PopUpLayout";
import CreateProject from "./CreateProject.vue";
export default {
  components: {
    ComponentLayout,
    CreateProject,
    // popUpLayout,
  },
  props: {},
  mounted() {
    this.getProjectFilters();
    this.getProjectsWithBoardsAndTasks();
  },

  methods: {
    itemClicked(item, action) {
      // this.$router.push(action.url);
      console.log(item, action);
      this.clickedItem = item;
      this.showEditForm = true;
    },
    paginationClicked(page) {
      this.filterCriteria.page = page;
    },
    getProjectFilters() {
      util
        .http({
          url: "tm/project/list-filter",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.filterCriteriaList = resp.data.data;
          }
        });
    },
    getProjectsWithBoardsAndTasks() {
      util
        .http({
          url: "tm/project/all-projects-with-boards-and-tasks",
          params: this.filterCriteria,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.projects = resp.data.data.data;
            this.filterCriteria.length = resp.data.data.last_page;
          }
        });
    },
  },
  watch: {
    filterCriteria: {
      deep: true,
      handler: function () {
        this.getProjectsWithBoardsAndTasks();
      },
    },
  },
  computed: {
    computedItems() {
      return util.constructTree(this.projects);
    },
  },

  data: () => ({
    projects: [],
    initiallyOpen: ["public"],
    basic: util.input.basic,
    filterCriteria: {
      length: 0,
      page: 1,
      search: "",
    },
    tree: [],
    filterCriteriaList: [],
    showEditForm: false,
    clickedItem: null,
  }),
};
</script>

<style scoped lang="scss">
.home-hover:hover {
  .icon {
    display: block;
  }
}
.home-hover {
  .icon {
    display: none !important;
  }
}
</style>
