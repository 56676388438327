<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-toolbar-title class="mb-6">Edit Company Setting</v-toolbar-title>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-if="data.type == 'text'"
              label="Value"
              placeholder="Enter First Name"
              v-model="data.value"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              v-if="data.type == 'textarea'"
              solo
              name="input-7-4"
              v-model="data.value"
              label="Value"
            ></v-textarea>
            <div v-if="data.type == 'checkbox'">
              <p>Two Factor Authentication</p>
              <v-switch
                v-if="data.type == 'checkbox'"
                v-model="switch1"
                :label="`${switch1 == true ? 'On' : 'Off'}`"
                place
              ></v-switch>
            </div>

            <!-- <v-switch
              v-if="data.type == 'checkbox'"
              v-model="data.value"
              :label="data.value"
              color="info"
              :value="data.value"
              hide-details
            ></v-switch> -->
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="data.type"
              :items="typeOptions"
              menu-props="auto"
              hide-details
              label="Select"
              single-line
            ></v-select>
            <!-- <v-select
              :items="typeOptions"
              item-text="label"
              :item-value="asdfa"
              label="Type"
              v-model="data.status"
              :rules="[rules.required]"
            ></v-select> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              v-model="status"
              :items="statusOptions"
              menu-props="auto"
              hide-details
              label="Select"
              single-line
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Sort"
              placeholder="Sort"
              v-model="data.sort"
              :rules="[rules.required, rules.number]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              elevation="2"
              large
              v-on:click="updateCompanySetting"
              >Update</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import util from "@/util/util";
export default {
  name: "CompanySettingEdit",
  data() {
    return {
      data: {},
      rules: util.validation,
      switch1: true,
      status: "",
      statusOptions: ["Active", "Inactive"],
      typeOptions: ["textarea", "text", "checkbox"],
    };
  },
  mounted() {
    this.fetchCompanySetting();
  },
  methods: {
    fetchCompanySetting() {
      this.$http({
        url: "company-setting/" + this.$route.params.id,
        method: "get",
      }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.data = res.data.data;
          if (res.data.data.value == 1) {
            this.switch1 = true;
          } else {
            this.switch1 = false;
          }
          if (res.data.data.status == 1) {
            this.status = "Active";
          } else {
            this.status = "Inactive";
          }
        }
      });
    },
    updateCompanySetting() {
      this.status == "Active" ? (this.data.status = 1) : (this.data.status = 0);
      if (this.data.type == "checkbox") {
        this.switch1 == true ? (this.data.value = 1) : (this.data.value = 0);
      }
      console.log(this.data);
      this.$refs.form.validate();
      this.$http({
        url: "company-setting/" + this.$route.params.id,
        method: "put",
        data: this.data,
      }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          util.notify(1, "Updated Successfully");

          this.$router.push({ name: "CompanySettingList" });
        }
      });
    },
  },
};
</script>
