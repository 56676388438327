<template>
  <v-dialog v-model="$store.state.showDropboxShareDialog" width="500">
    <v-card>
      <v-card-title
        class="text-h5"
        style="background-color: #0c2759; color: white"
      >
        Create A Shareable Link
      </v-card-title>

      <v-card-text>
        <v-select
          :items="['editor', 'viewer', 'viewer_no_comment']"
          label="Only people invited:"
          v-model="linkVisibility"
        ></v-select>
        <v-row v-for="(emailAddress, email) in emailAddresses" :key="email">
          <v-col class="d-flex" cols="12">
            <v-text-field
              v-model="emailAddress.email"
              label="Email Address"
              :name="`emailAddresses[${email}]`"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn block color="primary" @click="createLink"> Create Link </v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import util from "@/util/util";
import axios from "axios";
export default {
  props: {
    file: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      rules: util.validation,
      emailAddresses: [{ email: "" }],
      linkVisibility: "viewer",
      shared_folder_id: "",
    };
  },

  methods: {
    addRow() {
      this.emailAddresses.push({ email: "" });
    },
    deleteRow: function (idx) {
      this.emailAddresses.splice(idx, 1);
    },
    createLink() {
      if (this.$props.file.folder == true) {
        this.shareFolder();
      } else {
        this.shareFile();
      }
    },

    shareFile() {
      let file_data = {};
      let members = [];
      this.emailAddresses.map(({ email }) =>
        members.push({
          ".tag": "email",
          email: email,
        })
      );
      file_data = {
        file: this.$props.file.id,
        members: members,
        custom_message: "This is a custom message about ACME.doc",
        quiet: false,
        access_level: this.linkVisibility,
        add_message_as_comment: false,
      };

      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/sharing/add_file_member",
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
        data: file_data,
      }).then(() => {
        util.notify(1, "File Shared");
        this.$store.state.showDropboxShareDialog = false;
      });
    },
    shareFolder() {
      if (this.$props.file.parent_shared_folder_id) {
        util.notify(0, "Please share Parent Folder");
      } else {
        if (!this.$props.file.shared_folder_id) {
          this.getSharedFolderID();
        } else {
          this.addFolderMember();
        }
      }
    },

    getSharedFolderID() {
      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/sharing/share_folder",

        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
        data: {
          path: this.$props.file.path,
          acl_update_policy: "editors",
          force_async: false,
          member_policy: "team",
          shared_link_policy: "anyone",
          access_inheritance: "inherit",
        },
      }).then((res) => {
        this.shared_folder_id = res.data.shared_folder_id;
        this.addFolderMember();
      });
    },

    addFolderMember() {
      let members = [];
      let folder_data = {};
      this.emailAddresses.map(({ email }) =>
        members.push({
          member: {
            ".tag": "email",
            email: email,
          },
          access_level: { ".tag": this.linkVisibility },
        })
      );
      folder_data = {
        // shared_folder_id: this.$props.file.shared_folder_id,
        shared_folder_id: this.$props.file.shared_folder_id
          ? this.$props.file.shared_folder_id
          : this.shared_folder_id,
        members: members,
        quiet: false,
        custom_message: "Find my shared folder",
      };
      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/sharing/add_folder_member",
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
        data: folder_data,
      })
        .then(() => {
          util.notify(1, "Folder Shared");
          this.$store.state.showDropboxShareDialog = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style scoped>
.add-row-button {
  color: #fff !important;
  background: green !important;
  margin-top: -2rem;
  margin-bottom: 1rem;
}

.delete-row {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}
</style>
