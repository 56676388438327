<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="#1d2b58"
        style="color: white; margin-left: 10px"
        >Choose file <v-icon>mdi-file</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Choose File </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab> Upload File </v-tab>
          <v-tab> File List </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-file-input
                  accept="image/*"
                  label="Choose Files"
                  chips
                  multiple
                  prepend-icon=""
                  v-bind="basic"
                  v-model="data.file"
                ></v-file-input>
              </v-card-text>
              <v-btn
                block
                color="#1D2B58"
                style="color: white"
                v-on:click="uploadFile"
                >Upload Files</v-btn
              >
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- <v-card color="basil" flat>
              <v-card-text v-for="f in files" :key="f">
                <img :src="f" style="max-width: 200px" />
              </v-card-text >
              <p v-for="f in files" :key="f">{{ f }}</p>
            </v-card> -->
            <v-table
              :items="files"
              :sn="false"
              :only="['name']"
              :headingTransformer="headingTransformer"
              :additionalColumns="['Action']"
              :additionalColumnsTransformer="additionalColumnsTransformer"
              :html="['Action']"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <!-- <v-divider></v-divider> -->

      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Select </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
import util from "@/util/util.js";
import action from "@/components/shared/file/delete-file-button.vue";
export default {
  props: {
    show_template: {
      type: Boolean,
      default: true,
    },
    f: {
      type: Array,
    },
  },
  mounted() {
    util.event.$on("file-deleted", (file) => {
      this.files = this.files.filter(function(value) {
        return value != file;
      });
      this.data.file = this.data.file.filter(function(value) {
        return value.name != file.name;
      });
    });
  },
  data() {
    return {
      dialog: false,
      tab: 0,
      basic: util.input.basic,
      files: [],
      data: { file: [] },
    };
  },
  methods: {
    clicked(fun, id) {
      fun();
      this.$emit("input", id);
    },
    uploadFile() {
      let formData = new FormData();
      let i = 0;
      this.data.file.map((f) => {
        formData.append(`file[${i++}]`, f);
      });
      util
        .http({
          headers: {
            "Content-Type": "multipart/form-data",
          },
          url: "quotation/file-upload",
          method: "post",
          data: formData,
        })
        .then((res) => {
          if (res.data.status == 1) {
            util.notify(1, "Successfully Uploaded");
            res.data.data.forEach((d) => {
              this.files.push(d);
            });

            this.$emit("fileUploaded", this.files);
          }
        });
    },
    headingTransformer: function(val) {
      return util.camelCase(val);
    },
    additionalColumnsTransformer() {
      return {
        Action: (row) => {
          return [
            {
              comp: action,
              prop: {
                file: { row },
                name: "quotation",
              },
            },
          ];
        },
      };
    },
  },
  watch: {
    show_template: function(val) {
      this.dialog = val;
    },
    "$props.f": function(v) {
      this.files = v;
      this.data.file = v;
    },
  },
};
</script>
