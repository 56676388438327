<template >
  <component-layout :title="'Notification Logs'">
    <layout @filter="filter" :filterCriteriaProps="filterCriteria">
      <notify-card
        v-for="notification in notifications"
        :notification="notification"
        :key="notification.id"
      ></notify-card>
    </layout>
  </component-layout>
</template>
<script>
import ComponentLayout from "../component-layout.vue";
import Layout from "../Contact/PersonalContactsInfos/Layout.vue";
import NotifyCard from "../Contact/PersonalContactsInfos/NotifyCard.vue";
import Rest from "@/components/shared/rest";
export default {
  components: { NotifyCard, ComponentLayout, Layout },
  mounted() {
    this.fetchNotifications();
  },
  data: function () {
    return {
      notifications: [],
      filterCriteria: { length: 1, page: 1 },
    };
  },
  methods: {
    filter(p) {
      this.filterCriteria = p;
    },
    fetchNotifications() {
      Rest.get
        .call(this, {
          resource: "notification",
          prefix: "notification",
          params: this.filterCriteria,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.notifications = resp.data.data.data;
            this.filterCriteria.length = resp.data.data.last_page;
            this.notifications.forEach((n) => {
              n.data = JSON.parse(n.data);
            });
          }
        });
    },
  },
  watch: {
    filterCriteria: {
      handler: function () {
        this.fetchNotifications();
      },
      deep: true,
    },
  },
};
</script>