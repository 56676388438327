<template>
  <div>
    <v-col>
      <template>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-on:click="previewPDF(item)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-eye-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Preview PDF
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-on:click="downloadItem(item)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Download PDF
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-on:click="showFileUploadDialog(item)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-file-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  File Upload
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-on:click="showApproveDialog(item)"
                v-if="item.status == 0"
              >
                <v-list-item-icon>
                  <v-icon color="black">mdi-check-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Approve
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-on:click="showMailDialog(item)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-email-check-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Email
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-on:click="clickEdit(item.id)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Edit
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-on:click="clickDelete(item.id)">
                <v-list-item-icon>
                  <v-icon color="black">mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Delete
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template> </v-col
    ><v-dialog v-model="showDeleteModal" max-width="450px">
      <v-card>
        <v-card-title class="text-h5" style="word-break: break-word"
          >Confirm Action</v-card-title
        >
        <v-card-text class="text" style="font-size: 1.2rem; color:#000;"
          >Are you sure you want to do this?</v-card-text
        >
        <v-card-actions class="mt-3" style="padding-left: 24px">
          <v-btn
            color="#7a86ab"
            min-width="90px"
            class="button-color"
            @click="closeDialog"
            >No</v-btn
          >
          <v-btn
            color="#1d2b58"
            min-width="90px"
            class="button-color"
            @click="deleteResource"
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mailDialog" max-width="800px">
      <mailQuotation
        :item="item"
        @emailSent="closeDialog"
        v-if="$props.name == 'quotation'"
      />
      <mail :item="item" @emailSent="closeDialog" v-else />
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="450px">
      <approve :item="item" @closeDialog="closeDialog" />
    </v-dialog>
    <v-dialog v-model="pdfDialog" max-width="1200px">
      <div v-if="url != null">
        <pdf :url="url" @closeDialog="closeDialog" />
      </div>
    </v-dialog>
    <v-dialog v-model="fileUploadDialog" width="500px">
      <fileChooser
        :item="item"
        :name="$props.name"
        @closeDialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import mail from "./mail.vue";
import mailQuotation from "../Quotation/mail.vue";
import approve from "@/components/Quotation/approve";
import fileChooser from "@/components/shared/file/file-chooser";
import pdf from "./pdf-viewer.vue";
export default {
  props: {
    name: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  components: {
    mail,
    approve,
    fileChooser,
    mailQuotation,
    pdf,
  },
  mounted() {
    this.item = this.$props.data;
  },
  data() {
    return {
      showDeleteModal: false,
      mailDialog: false,
      approveDialog: false,
      fileUploadDialog: false,
      pdfDialog: false,
      id: null,
      item: {},
      url: null,
    };
  },

  methods: {
    downloadItem(item) {
      util
        .http({
          url: this.$props.name + "/download/" + item.id,
          method: "get",
          responseType: "blob",
        })
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            item.ref_no + "-" + this.$props.name + ".pdf"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    previewPDF(item) {
      util
        .http({
          url: this.$props.name + "/preview/" + item.id,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.url = res.data.data.url;
            this.pdfDialog = true;
          }
        });
    },
    clickEdit(id) {
      util.event.$emit("item-edit", id);
    },
    clickDelete(id) {
      this.id = id;
      this.showDeleteModal = true;
    },
    showMailDialog(item) {
      this.item = item;
      this.mailDialog = true;
    },
    showApproveDialog(item) {
      this.item = item;
      this.approveDialog = true;
    },
    showFileUploadDialog(item) {
      this.item = item;
      this.fileUploadDialog = true;
    },
    closeDialog() {
      this.mailDialog = false;
      this.approveDialog = false;
      this.showDeleteModal = false;
      this.fileUploadDialog = false;
      this.pdfDialog = false;
      this.url = null;
      this.util.event.$emit("reload-items", true);
    },
    deleteResource() {
      this.$http({
        url: this.$props.name + "/" + this.id,
        method: "delete",
      }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          util.notify(
            1,
            this.$props.name.charAt(0).toUpperCase() +
              this.$props.name.slice(1) +
              " deleted"
          );
          util.event.$emit("reload-items", true);
          this.showDeleteModal = false;
        }
      });
    },
  },
};
</script>

<style>
.button-color {
  color: #ffffff !important;
}
</style>
