<template>
  <div>
    <progress1 @cancelUpload="cancelUpload" :files="files" v-if="show" />
  </div>
</template>
<script>
import progress1 from "@/components/shared/progress";
import util from "@/util/util";
import axios from "axios";

export default {
  components: {
    progress1,
  },
  mounted() {
    util.event.$off("filesUploadedGoogle");
    util.event.$on("filesUploadedGoogle", (files, parent) => {
      this.show = true;
      this.$set(this, "files", []);
      this.$set(
        this,
        "files",
        Array.from(files).map((f) => {
          return { name: f.name, progress: 0, url: "", cancelRequest: false };
        })
      );
      this.upload(files, parent);
    });
  },
  data() {
    return {
      files: [],
      show: false,
    };
  },
  methods: {
    cancelUpload(file) {
      file.cancelRequest = true;
      if (file.id && file.progress != 100) {
        let url = `https://www.googleapis.com/drive/v3/files/${file.id}`;
        axios({
          method: "delete",
          url: url,
          headers: {
            Authorization: "Bearer " + this.$store.state.drive_token,
            Accept: "application/json",
          },
        });
      }
      file.progress = 0;
    },
    async upload(files, parents) {
      this.fileToBeUploaded = files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let currentFile = this.files.find((f) => f.name == file.name);
        if (currentFile.cancelRequest) {
          this.cancelUpload(currentFile);
          continue;
        }
        let metadata = {
          name: file["name"],
          mimeType: file["type"],
          parents: [parents.id],
        };
        await axios({
          method: "post",
          url:
            "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&fields=*",
          headers: {
            Authorization: "Bearer " + this.$store.state.drive_token,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(metadata),
        })
          .then(async ({ headers: { location } }) => {
            let chunkCount = Math.ceil(file.size / util.CHUNK_SIZE.GOOGLEDRIVE);
            for (let i = 0; i < chunkCount; i++) {
              if (currentFile.cancelRequest) {
                this.cancelUpload(currentFile);
                break;
              }
              let contentRange =
                "bytes " +
                i * util.CHUNK_SIZE.GOOGLEDRIVE +
                "-" +
                ((i + 1) * util.CHUNK_SIZE.GOOGLEDRIVE - 1 > file.size
                  ? file.size - 1
                  : (i + 1) * util.CHUNK_SIZE.GOOGLEDRIVE - 1) +
                "/" +
                file.size;

              await axios({
                validateStatus: (status) => {
                  return status <= 308;
                },
                method: "PUT",
                url: location,
                headers: {
                  "Content-Range": contentRange,
                },
                data: file.slice(
                  i * util.CHUNK_SIZE.GOOGLEDRIVE,
                  (i + 1) * util.CHUNK_SIZE.GOOGLEDRIVE
                ),
              }).then((res) => {
                currentFile.progress = Math.round(((i + 1) / chunkCount) * 100);
                currentFile.id = res.data.id;
                if (res.status == 200) {
                  util.event.$emit("refreshFileList");
                }
              });
            }
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status == 401) {
                this.$store.commit("setDriveToken", null);
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.progress-wrapper {
  width: 320px;
  position: fixed;
  right: 0;
  bottom: 20px;
}
.upload-title {
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #0c2759;
  color: white;
  font-weight: bold;
}
</style>
