<template>
  <div>
    <border-box label="Task --> Board">
      <v-row class="row justify-space-between align-center">
        <v-col cols="4" md="4" sm="12" lg="4">
          <v-text-field
            v-bind="basic"
            label="Search"
            append-icon="search"
            value=""
            outlined
          ></v-text-field>
        </v-col>
        <div class="">
          <button class="add-new-btn mb-5">+ ADD NEW SECTION</button>
        </div>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="4" md="4" sm="12" lg="4">
          <TaskType Task="Task" />
        </v-col>
        <v-col cols="4" md="4" sm="12" lg="4">
          <TaskType Task="Task" />
        </v-col>
        <v-col cols="4" md="4" sm="12" lg="4">
          <TaskType Task="Task" />
        </v-col>
      </v-row>
    </border-box>
  </div>
</template>

<script>
import borderBox from "../components/Quotation/border-box.vue";
import TaskType from "../components/Board/TaskType.vue";

import util from "../util/util";
export default {
  components: {
    borderBox,
    TaskType,
    //  Completed,
    // Todo,
    // OnGoing
  },
  name: "Board",

  data() {
    return {
      basic: util.input.basic,
    };
  },
};
</script>

<style scoped>
.add-new-btn {
  background: #1d2b58;
  color: #fff;
  padding: 10px 30px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0;
  margin-bottom: 0px !important;
  display: none !important;
}
</style>
