<template>
  <div>
    <div class="event-wrapper">
      <v-form v-model="isValid" ref="form">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select
              label="Event Type"
              :items="items"
              v-model="form.event_type_id"
              :rules="[rules.required]"
              v-bind="basic"
              required
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        @click="showAddEventTypeDialog()"
                        @closeDialog="closeDialog"
                        class="item-button"
                      >
                        <v-icon>mdi-plus</v-icon>Add Event Type
                      </v-btn>
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Name"
              v-model="form.name"
              :rules="[rules.required]"
              required
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Venue"
              v-model="form.venue"
              :rules="[rules.required]"
              required
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="form.date"
                  clearable
                  label="Date"
                  outlined
                  readonly
                  v-bind="{ ...attrs, ...basic }"
                  v-on="on"
                  @click:clear="form.date = null"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.time"
                  label="Event Time"
                  readonly
                  outlined
                  v-bind="{ ...attrs, ...basic }"
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="form.time"
                full-width
                @click:minute="$refs.menu.save(form.time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-combobox
              label="Participant"
              :items="customers"
              v-model="customerparticipant"
              v-bind="basic"
              required
              multiple
              chips
            >
              <template #selection="{ item }">
                <v-chip elevation="0" style="margin: 5px">
                  {{ item.text }}
                  <div class="cross-circle" @click="removeItem(item)">
                    <v-icon style="font-size: 15px">mdi-close</v-icon>
                  </div>
                </v-chip>
              </template>

              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddEventParticipantDialog()"
                        class="item-button"
                        ><v-icon>mdi-plus</v-icon>Add New Participant</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="6" md="6" v-if="emailparticipant.length > 0">
            <span v-for="(item, index) of emailparticipant" :key="index">
              <v-chip elevation="0" style="margin: 5px" v-if="item.email != ''">
                {{ item.email }}
                <div class="cross-circle" @click="removeItem(index)">
                  <v-icon style="font-size: 15px">mdi-close</v-icon>
                </div>
              </v-chip>
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              label="Reminders"
              :items="reminders"
              v-model="form.reminder_id"
              :rules="[rules.required]"
              v-bind="basic"
              required
              chips
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        @click="showAddReminderDialog()"
                        @closeDialog="closeDialog"
                        class="item-button"
                        ><v-icon>mdi-plus</v-icon>Add Customer Reminder</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-textarea
              label="Note"
              v-model="form.note"
              :rules="[rules.required]"
              required
              v-bind="basic"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-btn color="primary" @click="createTask" :disabled="!isValid">
              <span v-if="$props.id === 0">Create Event</span>
              <span v-else>Update Event</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-dialog v-model="showDialog" max-width="600px">
      <addEventParticipant
        @getParticipant="getEventParticipant($event)"
        @closeDialog="closeDialog"
      />
    </v-dialog>
    <v-dialog v-model="showRDialog" max-width="600px">
      <addReminder @closeDialog="closeDialog" @fetchReminder="fetchReminder" />
    </v-dialog>
    <v-dialog v-model="showAddEventDialog" max-width="600px">
      <addEventType
        @closeDialog="closeDialog"
        @fetchEventTypes="getEventTypes"
      />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import moment from "moment";
import addEventParticipant from "@/components/events/AddEventParticipant";
import addReminder from "@/components/events/AddReminder";
import addEventType from "@/components/events/AddEventType";

export default {
  components: { addReminder, addEventParticipant, addEventType },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getEventTypes();
    this.getCustometList();
    this.getReminderList();
    if (this.$props.id !== 0) {
      this.fetchEvent();
    }
  },
  data() {
    return {
      rules: util.validation,
      isValid: false,
      showDialog: false,
      showRDialog: false,
      showAddEventDialog: false,
      items: [],
      customers: [],
      reminders: [],
      form: {
        date:
          this.$props.date !== ""
            ? this.$props.date
            : moment().format("YYYY-MM-DD"),
        time:
          this.$props.time !== "" ? this.$props.time : moment().format("HH:mm"),
        participant: [],
      },
      menu1: false,
      customerparticipant: [],
      emailparticipant: {},
      basic: util.input.basic,
    };
  },
  methods: {
    fetchEvent() {
      util
        .http({
          url: "/event/" + this.$props.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.form = res.data.data;
            this.form.participant = [];
            this.form.event_participants.map((p) => {
              this.customerparticipant.push({
                value: p.customer_id,
                text: p.customer.full_name,
              });
            });
          }
        })
        .catch();
    },
    removeItem(data) {
      console.log("data:", data);
      typeof data == "number"
        ? this.emailparticipant.splice(data, 1)
        : this.customerparticipant.splice(
            this.customerparticipant.indexOf(data),
            1
          );
      // this.emailparticipant.splice(index, 1);
    },
    getEventParticipant(data) {
      this.emailparticipant = data;
      console.log("parent:", this.emailparticipant);
    },
    closeDialog() {
      this.showDialog = false;
      this.showRDialog = false;
      this.showAddEventDialog = false;
    },
    fetchReminder() {
      this.getReminderList();
    },
    getEventTypes() {
      util
        .http({
          url: "/event-type",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.items = resp.data.data.data.map((i) => {
              return { text: i.name, value: i.id };
            });
          }
        })
        .catch();
    },
    getCustometList() {
      util
        .http({
          url: "/customer",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.customers = resp.data.data.data.map((i) => {
              return {
                text:
                  i.first_name +
                  (i.middle_name == null ? " " : i.middle_name) +
                  i.last_name,
                value: i.id,
              };
            });
          }
        })
        .catch();
    },
    getReminderList() {
      util
        .http({
          url: "/reminder/get-all",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.reminders = resp.data.data.map((i) => {
              return {
                text: i.value + " " + i.unit,
                value: i.id,
              };
            });
          }
        })
        .catch();
    },
    createTask() {
      this.createParticipant();
      console.log("form:", this.form);
      let url = this.$props.id === 0 ? "/event" : `/event/${this.$props.id}`;
      this.isValid
        ? (util.loader(1),
          util
            .http({
              url: url,
              method: this.$props.id === 0 ? "POST" : "PUT",
              data: {
                ...this.form,
              },
            })
            .then((resp) => {
              util.loader(0);
              if (resp.data.status == 1) {
                util.notify(
                  resp.data.status,
                  this.$props.id === 0 ? "Event Created" : "Event Updated"
                );
                this.$refs.form.reset();
                this.form.participant = [];
                this.emailparticipant = {};
                this.customerparticipant = "";
                util.event.$emit("clearAddEmailParticipant", true);
              }
            }))
        : null;
    },
    createParticipant() {
      console.log("customerparticipant: ", this.customerparticipant);
      for (let index = 0; index < this.customerparticipant.length; index++) {
        this.form.participant.push({
          email: null,
          customer_id: this.customerparticipant[index].value,
        });
      }
      for (const data in this.emailparticipant) {
        if (this.emailparticipant[data]["email"] !== "")
          this.form.participant.push({
            email: this.emailparticipant[data]["email"],
            customer_id: null,
          });
      }
    },
    showAddEventParticipantDialog() {
      this.showDialog = !this.showDialog;
    },
    showAddReminderDialog() {
      this.showRDialog = !this.showRDialog;
    },
    showAddEventTypeDialog() {
      this.showAddEventDialog = true;
    },
  },
};
</script>
<style scoped>
.event-wrapper {
  padding-top: 30px;
}
.additional-participant {
  border: 1px solid black;
  padding: 10px;
}
.cross-circle {
  background-color: gray;
  border-radius: 50%;
  margin-left: 10px;
  width: 25px;
  /* height: 25px; */
  align-items: center;
  text-align: center;
}
.item-button {
  background: #1d2b58 !important;
  color: #fff !important;
}
</style>
