<template>
  <div>
    <div>
      <router-link
        :to="{ name: $props.edit.name, params: { id: $props.edit.params.id } }"
        class="nav-link-button"
        exact
        title="Edit"
        ><v-icon small> mdi-pencil </v-icon>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    edit: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.fa-trash {
  color: #cc0000;
  cursor: pointer;
}

.fa-edit {
  color: #ffbf00;
}
.confirm-box {
  display: flex;
  justify-content: space-evenly;
}
.nav-link-button {
  display: inline !important;
  padding: 2px !important;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important ;
}
</style>
