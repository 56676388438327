<template>
  <div>
    <div class="bg-inner mx-5 px-3 pt-3 pb-1" :style="cssVars">
      <p class="font-weight-bold">TASK 1</p>
      <div class="row">
        <span class="p-0 m-0 mr-2">2020/10/24</span>
        <div class="urgent-status">Urgent</div>
      </div>
      <p class="mt-5">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a........
      </p>
    </div>
  </div>
</template>

<script>
export default {
    props: ['color'],
    computed: {
      cssVars () {
        return{
          /* variables you want to pass to css */
          '--color': this.color,
          }
        }
    }
}
</script>

<style scoped>

.bg-inner {
  background-color: var(--color)
}
</style>
