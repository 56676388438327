<template>
  <div>
    <v-row>
      <v-col cols="6" style="max-height: 84vh; overflow-y: scroll;">
        <!-- <contactList /> -->
        <component-layout title="customer list">
          <template #menu>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-auto bg-btn"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <router-link
                    class="text-decoration-none font-weight-medium"
                    :to="{ name: 'AddContact' }"
                    ><v-list-item-title class="text-decoration-none" v-ripple
                      >ADD</v-list-item-title
                    ></router-link
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!-- <layout> -->
          
            <v-table
              class="qt"
              url="/customer"
              :rowClicked="rowClicked"
              :only="['full_name', 'email']"
              :additionalColumns="['full_name']"
              :additionalColumnsTransformer="additionalColumnsTransformer"
              :html="['full_name']"
              :headingTransformer="headingTransformer"
              :columnSortOrder="{ full_name: 1 }"
              :sn="false"
            >
              <template #search>
                <div>hi</div>
              </template>
            </v-table>
          
          <!-- </layout> -->
        </component-layout>
      </v-col>

      <v-col cols="6" class="px-0">
        <div style="max-height: 84vh; overflow-y: auto">
          <router-view />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import util from "@/util/util";
import ComponentLayout from "../component-layout.vue";
// import Layout from "./PersonalContactsInfos/Layout.vue";
// @ is an alias to /src
// import contactList from "@/components/Contact/ContactList";
export default {
  name: "ContactIndex",
  components: {
    ComponentLayout,
    // Layout,
    // contactList,
  },

  methods: {
    headingTransformer: function(val) {
      return util.camelCase(val);
    },
    additionalColumnsTransformer() {
      return {
        full_name: (row) => {
          return [
            {
              item:
                row.first_name + " " + row.middle_name + " " + row.last_name,
              handler: () => null,
            },
          ];
        },
      };
    },
    rowClicked(row) {
      this.$router
        .push({ name: "AdditionalInformation", params: { id: row.id } })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.qt table td:nth-of-type(odd) {
  background-color: #f2f2f2 !important;
}
</style>
