<template>
  <div style="padding: 1rem">
    <h2>Send Quotation Email</h2>
    <v-form style="padding: 1rem 0">
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="To"
            v-bind="basic"
            v-model="form.email"
            :value="form.email"
            :rules="[rules.required, rules.email]"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Subject"
            v-bind="basic"
            v-model="form.subject"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12">
          <fileUpload
            @file-upload-success="fileUploadSuccess"
            @delete-file="deleteFile"
        /></v-col>
        <v-col cols="12">
          <p>Message Body</p>
          <tiptap-vuetify
            v-bind="basic"
            v-model="form.content"
            :extensions="extensions"
            placeholder="Message Body"
            :rules="[rules.required]"
            min-height="200px"
          />
        </v-col>
      </v-row>
      <v-row class="mt-7">
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="notify()">Send</v-btn>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import fileUpload from "@/components/Contact/PopupForms/UploadFiles.vue";
export default {
  components: {
    TiptapVuetify,
    fileUpload,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data: function() {
    return {
      basic: util.input.basic,
      rules: util.validation,
      form: {
        email: this.$props.item ? this.$props.item.customer.email : "",
        all_customer: false,
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  methods: {
    fileUploadSuccess(file, res) {
      this.attachments[file.name] = res.data[0].url;
    },
    deleteFile(file) {
      let url = this.attachments[file.name];
      util
        .http({
          url: "file-remove",
          method: "post",
          data: { url, name: file.name },
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            delete this.attachments[file.name];
            util.notify(1, "Removed");
          }
        });
    },
    notify() {
      this.form.notify_via = "mail";
      this.form.attachments = [];
      this.form.customer_id = this.$props.item.customer.id;
      for (var key in this.attachments) {
        this.form.attachments.push(this.attachments[key]);
      }
      util
        .http({
          url:
            "notification/quotation/send-notification/" + this.$props.item.id,
          method: "post",
          data: this.form,
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Sent");
            this.form = {};
            this.$emit("emailSent", true);
          }
        });
    },
  },
  watch: {
    "$props.item": {
      handler: function() {
        this.form.email = this.$props.item.customer.email;
      },
      deep: true,
    },
  },
};
</script>
