<template>
  <div>
    <v-col cols="12" md="12">
      <pop-up-layout title="Additional Inputs">
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab> Additional info </v-tab>
          <v-tab> Bulk Input </v-tab>
          <v-tab> Set Base Customer </v-tab>
        </v-tabs>
        <v-container :key="key">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-text-field
                class="mb-5"
                v-bind="basic"
                color="primary"
                label="Field Label"
                required
                outlined
                v-model="form.field_label"
              ></v-text-field>
              <v-select
                class="mb-5"
                v-model="selectedInputType"
                v-bind="basic"
                :items="items"
                label="Select Field Type"
                outlined
              ></v-select>

              <v-text-field
                class="mb-5"
                v-if="selectedInputType == 'text'"
                v-bind="basic"
                color="primary"
                label="Field Value"
                required
                outlined
                v-model="form.field_value"
              ></v-text-field>

              <!-- <v-file-input
            class="mb-5"
            v-bind="basic"
            v-if="selectedInputType == 'file'"
            truncate-length="10"
            label="File"
            outlined
            v-model="file"
            @change="uploadFile()"
          ></v-file-input> -->

              <fileUpload
                class="mb-5"
                v-bind="basic"
                v-if="selectedInputType == 'file'"
                @file-upload-success="fileUploadSuccess"
                @delete-file="deleteFile"
              />

              <v-textarea
                class="mb-5"
                v-bind="basic"
                v-if="selectedInputType == 'textarea'"
                outlined
                name="input-7-4"
                label="Field Value"
                value=""
                v-model="form.field_value"
              ></v-textarea>
              <v-btn depressed color="primary" v-on:click="submit">
                SAVE
              </v-btn>
            </v-tab-item>
            <v-tab-item>
              <BulkInput />
            </v-tab-item>
            <v-tab-item>
              <AddBaseCustomer />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </pop-up-layout>
    </v-col>
  </div>
</template>

<script>
import util from "@/util/util";
import fileUpload from "./UploadFiles.vue";
import PopUpLayout from "../../PopUpLayout.vue";
import BulkInput from "./AdditionalInfo/BulkInput.vue";
import AddBaseCustomer from "./AdditionalInfo/BaseCustomer.vue";
export default {
  components: { PopUpLayout, fileUpload, BulkInput, AddBaseCustomer },
  data: () => ({
    key: 0,
    basic: util.input.basic,
    items: ["text", "file", "textarea"],
    selectedInputType: null,
    form: { field_value: "", field_label: "", field_type: "" },
    file: {},
    delete: true,
    tab: 0,
  }),
  mounted() {
    util.event.$on("reload-bulk-info", () => {
      this.key++;
      this.tab = 1;
    });
  },
  methods: {
    submit() {
      let data = {
        items: [],
        customer_id: this.$route.params.id,
      };
      data.items.push(this.form);
      util
        .http({
          url: "customer/attach-additional-fields",
          method: "post",
          data: data,
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Additional Info Stored");
            this.delete = false;
            this.data = { items: {} };
            this.selectedInputType = "";
            this.form = { field_value: "", field_label: "", field_type: "" };
            util.event.$emit("refreshResource");
          }
        });
    },
    fileUploadSuccess(file, res) {
      this.form.field_value = res.data[0].url;
      this.delete = true;
    },
    deleteFile(file) {
      if (this.delete) {
        let url = this.form.field_value;
        util
          .http({
            url: "file-remove",
            method: "post",
            data: { url, name: file.name },
          })
          .then((res) => {
            if (res.data.status == 0) {
              util.notify(0, res.data.errors[0]);
            } else {
              delete this.form.field_value;
              util.notify(1, "Removed");
            }
          });
      }
    },
  },
  watch: {
    selectedInputType: function() {
      this.form.field_value = "";
      this.form.field_type = this.selectedInputType;
    },
  },
};
</script>
