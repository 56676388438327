<template>
  <div class="my-3">
    <div class="d-flex note-border py-2 px-3">
      <v-icon class="mr-5" x-large>note</v-icon>
      <div class="col-md-12">
        <div class="row py-3 d-flex justify-space-between col-md-12">
          <h3>{{ $props.note.title }}</h3>
          <span class="px-4">{{ date }}</span>
        </div>
        <div
          class="d-flex justify-space-between delete-button"
          style="padding-right:2.5rem;"
        >
          <span>
            {{ $props.note.description }}
          </span>
          <span>
            <v-icon v-on:click="editNote($props.note)">mdi-pencil</v-icon>
            <v-icon v-on:click="showDeleteModal = true">mdi-delete</v-icon>
          </span>
        </div>
      </div>
    </div>
    <v-dialog v-model="showDeleteModal" max-width="450px">
      <deleteDialog
        resource="note"
        :id="$props.note.id"
        @cancel="cancel"
        @delete-success="deleteSuccess"
      />
    </v-dialog>
  </div>
</template>

<script>
import util from "@/util/util";
import deleteDialog from "@/components/shared/delete-modal";
export default {
  components: { deleteDialog },
  data() {
    return {
      util,
      date: "",
      showDeleteModal: false,
    };
  },
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.date = util.changeDateFormat(this.$props.note.created_at);
  },
  methods: {
    cancel() {
      this.showDeleteModal = false;
    },
    deleteSuccess() {
      this.showDeleteModal = false;
      util.notify(1, "Note deleted");
      this.$emit("reload-resource", true);
    },
    editNote(n) {
      this.$emit("edit-note", n);
    },
  },
};
</script>

<style scoped>
.note-border {
  border: 1px solid #1d2b58;
}

.delete-button {
  cursor: pointer;
}
</style>
