<template>
  <v-dialog v-model="$store.state.showGoogleDriveShareDialog" width="500">
    <v-card>
      <v-card-title
        class="text-h5"
        style="background-color: #0c2759; color: white"
      >
        Create A Shareable Link
      </v-card-title>

      <v-card-text>
        <v-select
          :items="['Public', 'Specific Email Address']"
          label="Link Visibility"
          v-model="linkVisibility"
        ></v-select>
        <div v-if="linkVisibility == 'Specific Email Address'">
          <!-- <v-row v-for="(emailAddress, email) in emailAddresses" :key="email"> -->
          <!-- <v-col class="d-flex" cols="11">
              <v-text-field
                v-model="emailAddress.email"
                label="Email Address"
                :name="`emailAddresses[${email}]`"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col> -->
          <v-row>
            <v-col class="d-flex" cols="11">
              <v-text-field
                v-model="emailAddresses"
                label="Email Address"
                name="emailAddresses"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="1" class="delete-row-btn">
              <i class="fa fa-trash delete-row" v-on:click="deleteRow"></i>
            </v-col> -->
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-btn
                elevation="2"
                small
                v-on:click="addRow"
                class="add-row-button"
              >
                <i class="fa fa-plus"></i
              ></v-btn>
            </v-col>
          </v-row> -->
          <!-- <hr style="margin: 5px 0" /> -->
        </div>
        <v-btn block color="primary" @click="createLink"> Create Link </v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import util from "@/util/util";
import axios from "axios";
export default {
  props: {
    fileId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      rules: util.validation,
      // emailAddresses: [{ email: "" }],
      emailAddresses: "",
      linkVisibility: "Public",
    };
  },
  methods: {
    // addRow() {
    //   this.emailAddresses.push({ email: "" });
    // },
    // deleteRow: function(idx) {
    //   this.emailAddresses.splice(idx, 1);
    // },
    createLink() {
      console.log(this.emailAddresses);
      let data = {};
      if (this.linkVisibility == "Public") {
        data = { role: "reader", type: "anyone" };
      } else {
        // let emails = [];
        // this.emailAddresses.map(({ email }) => emails.push(email));
        data = {
          role: "reader",
          type: "group",
          emailAddress: this.emailAddresses,
        };
      }
      let url = `https://www.googleapis.com/drive/v3/files/${this.$props.fileId}/permissions`;
      axios({
        method: "post",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
        data: data,
      })
        .then(() => {
          util.notify(1, "File Shared");
          this.getFileLink(this.$props.fileId);
          util.event.$emit("file-shared", true);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            } else {
              util.notify(0, e.response.data.error.message);
            }
          }
        });
    },
    getFileLink(fileId) {
      let url = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=*`;
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          this.$copyText(res.data.webViewLink).then(() => {
            util.notify(1, "Link Copied to Clipboard");
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.add-row-button {
  color: #fff !important;
  background: green !important;
  margin-top: -1rem;
  /* margin-bottom: 1rem; */
  width: 100%;
}

.delete-row {
  cursor: pointer;
}

.delete-row-btn {
  padding-top: 30px;
}

.text-align-center {
  text-align: center;
}
</style>
