<template>
  <nav>
    <v-app-bar dark app style="background-color: #0b2559">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase">
        <!-- <span class="font-weight-light">AAE</span> -->
        <span>CRM</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon left>expand_more</v-icon>
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list flat>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
            active-class="border"
          >
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text v-on:click="logout">
        <span>Logout</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
      style="background-color: #0b2559"
    >
      <v-layout column align-center>
        <v-flex class="mt-5">
          <p class="white--text subheading mt-1 text-center">Username</p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <!-- <Popup /> -->
        </v-flex>
      </v-layout>
      <v-list flat>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
// import Popup from './Popup.vue'
import util from "@/util/util";
export default {
  data: () => ({
    drawer: true,
    links: [
      { icon: "dashboard", text: "Home", route: "/" },
      { icon: "folder", text: "Contacts", route: "/contacts" },
      { icon: "work", text: "Events", route: "/events" },
      { icon: "sms", text: "Notification", route: "/notification" },
      { icon: "person", text: "Reports", route: "/reports" },
      { icon: "folder", text: "Files", route: "/files" },
      { icon: "person", text: "Company Setting", route: "/company-setting" },
      { icon: "person", text: "Quotation", route: "/quotation" },
      { icon: "person", text: "PO", route: "/po" },
      { icon: "person", text: "Task Management", route: "/task" },
    ],
  }),
  components: {
    // Popup
  },
  methods: {
    logout() {
      if (confirm("Are you sure you want to log out?")) {
        // sessionStorage.removeItem("token");
        // this.$router.push({ name: "Login" });
        util.http
          .post("logout")
          .then((res) => {
            console.log(res);
            sessionStorage.removeItem("token");
            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            sessionStorage.removeItem("token");
            this.$router.push({ name: "Login" });
          });
      }
    },
  },
};
</script>
<style scoped>
.border {
  /* border-left: 4px solid #0ba518; */
  background-color: #576ea9;
}
</style>