<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-toolbar-title class="mb-6">Add Participant</v-toolbar-title>
        <v-row v-for="(data, index) in eventParticipant" :key="index">
          <v-col class="d-flex" cols="12" sm="5" md="5"> </v-col>
          <v-col cols="11" sm="11" md="11">
            <v-text-field
              v-model="data.email"
              label="Enter Email"
              placeholder="Enter Email"
              :name="`eventParticipant[${index}][value]`"
              :rules="[rules.email]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1" v-if="index != 0">
            <v-icon v-on:click="deleteRow(index)" class="delete-row-button"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn class="add_new_item" v-on:click="addRow"> Add More </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              elevation="2"
              large
              v-on:click="storeParticipant"
              block
              v-bind="basic"
              >Add New Participant</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import util from "@/util/util";
export default {
  data() {
    return {
      data: {},
      rules: util.validation,
      companyOptions: [],
      stateOptions: util.stateOptions,
      customerTypeOptions: [],
      contactTypeOptions: [],
      countriesOption: [],
      eventParticipant: [
        {
          email: "",
        },
      ],
      basic: util.input.basic,
    };
  },
  mounted() {
    util.event.$on("clearAddEmailParticipant", () => {
      this.eventParticipant = [
        {
          email: "",
        },
      ];
    });
  },
  methods: {
    clearEventParticipant() {},
    storeParticipant() {
      if (this.$refs.form.validate()) {
        this.$emit("getParticipant", this.eventParticipant);
        this.$emit("closeDialog", true);
        console.log("child: ", this.eventParticipant);
      }
    },
    addRow() {
      if (this.$refs.form.validate())
        this.eventParticipant.push({
          email: "",
        });
    },
    deleteRow: function (index) {
      this.eventParticipant.splice(index, 1);
    },
  },
};
</script>
<style>
.delete-row-button {
  padding-top: 8px;
}
.add_new_item {
  display: block !important;
  margin: 0 auto;
  background-color: #aaa !important;
}
.v-dialog {
  background: white;
}
</style>
