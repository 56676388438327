<template>
  <div>
    <div class="taxt-dash">
      <border-box label="Task Dashboard">
        <v-row class="">
          <v-col cols="6" sm="12" md="6" lg="6">
            <TaskDashboard />
            <div class="px-2 py-4">
              <!-- <private-note></private-note> -->
            </div>

            <!-- <Notes /> -->
          </v-col>
          <v-col cols="6" sm="12" md="6" lg="6">
            <border-box>
              <router-view></router-view>
            </border-box>
          </v-col>
        </v-row>
      </border-box>
    </div>
  </div>
</template>

<script>
import borderBox from "../components/Quotation/border-box.vue";
import TaskDashboard from "../components/Task/TaskDashboard.vue";
// import PrivateNote from "../components/PrivateNote.vue";
// import Notes from "../components/Notes.vue";

export default {
  components: {
    borderBox,
    TaskDashboard,
    //  PrivateNote,
    // Notes,
  },
};
</script>
