<template>
  <section
    class="mb-15 qt"
    style="position: relative; width: 800px; margin: 0 auto"
  >
    <v-row>
      <v-col cols="12">
        <!-- <contactList /> -->
        <v-row>
          <v-col
            class="pl-8 text-center"
            cols="4"
            style="background-color: #1d2b58; color: white; padding: 20px"
          >
            <!-- <h2>Invoice Logo</h2> -->

            <img src="/img/Picture.png" />
            <div style="display: block; justify-content: center">
              <div>
                <h3>
                  {{
                    $props.name.charAt(0).toUpperCase() + $props.name.slice(1)
                  }}
                  to:
                </h3>
              </div>
              <div>
                <h3>{{ $props.item.customer.full_name }}</h3>
              </div>
              <div>
                <h3>
                  {{ $props.item.customer.address }},
                  {{ $props.item.customer.city }},
                  {{ $props.item.customer.state }},
                  {{ $props.item.customer.country }}
                </h3>
              </div>
            </div>
          </v-col>
          <v-col cols="8">
            <p class="text-right title-invoice mr-10">
              {{ $props.name.toUpperCase() }}
            </p>
            <v-row>
              <v-col cols="5" md="5"> </v-col>
              <v-col cols="7" md="7" class="text-end">
                <div class="mr-10">
                  <div class="d-flex justify-space-between">
                    <h3 class="">{{ $props.name.toUpperCase() }}#</h3>
                    <h3 class="">{{ $props.item.id }}</h3>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h3 class="">Ref Number</h3>
                    <h3>{{ $props.item.ref_no }}</h3>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h3 class="mr-15">Date</h3>
                    <h3>{{ changeDateFormat($props.item.issue_date) }}</h3>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-15" style="background: #faf7f7">
      <v-col cols="12">
        <v-row style="background: #faf7f7">
          <v-container>
            <table style="width: 100%">
              <tr>
                <th>SL.</th>
                <th>Item Description</th>
                <th>Price</th>
                <th>Qty.</th>
                <th>Total</th>
              </tr>
              <tr v-for="(p, i) in $props.item.products" :key="p.id">
                <td v-if="approval">
                  <v-checkbox
                    v-bind="basic"
                    v-model="form.products"
                    :value="p.product_id"
                  >
                  </v-checkbox>
                </td>
                <td v-else>{{ i + 1 }}</td>
                <td>{{ p.description }}</td>
                <td>${{ p.price }}</td>
                <td>{{ p.quantity }}</td>
                <td>${{ p.price * p.quantity }}</td>
              </tr>
            </table>

            <div class="clip-path-4"></div>
          </v-container>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" class="">
        <v-container class="px-15">
          <v-row>
            <!-- <v-col cols="1"></v-col> -->

            <v-col cols="6">
              <div class="pt-4" v-if="$props.name === 'invoice'">
                <h2>Payment Info</h2>
                <div class="d-flex justify-space-between">
                  <h4 class="mr-15 py-2">Account #:</h4>
                  <h4>1234 5678 9012</h4>
                </div>
                <div class="d-flex justify-space-between">
                  <h4 class="mr-15 py-2">Account Name:</h4>
                  <h4>Lorem Ipsum</h4>
                </div>
                <div class="d-flex justify-space-between">
                  <h4 class="mr-15 py-2">Bank Details:</h4>
                  <h4>Your Details</h4>
                </div>
              </div>

              <div class="mt-4">
                <h4>Terms & Conditions</h4>
                <div class="mb-5 mt-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </div>
                <v-divider style="border-bottom: 2px solid"></v-divider>
              </div>
            </v-col>

            <v-col cols="1"></v-col>

            <v-col cols="5">
              <div class="d-flex justify-space-between">
                <h4 class="mr-15 py-2">Sub total:</h4>
                <h4>${{ subTotal }}</h4>
              </div>
              <div
                class="d-flex justify-space-between"
                v-for="t in $props.item.tax"
                :key="t.id"
              >
                <h4 class="mr-15 py-2">{{ getName(t) }}:</h4>
                <h4>${{ getTaxAmount(t) }}</h4>
              </div>
              <div class="d-flex justify-space-between">
                <h4 class="mr-15 py-2">Total Tax:</h4>
                <h4>${{ taxTotal }}</h4>
              </div>
              <div class="d-flex justify-space-between">
                <h4 class="mr-15 py-2">Discount:</h4>
                <h4>${{ $props.item.discount }}</h4>
              </div>

              <v-divider style="border-bottom: 2px solid"></v-divider>
              <div class="d-flex justify-space-between pb-15">
                <h3 class="mr-15 py-2">Total:</h3>
                <h3>${{ grandTotal }}</h3>
              </div>

              <div class="d-flex justify-end mt-15 ml-auto" style="width: 70%">
                <v-divider style="border-bottom: 2px solid"> </v-divider>
              </div>
              <h3 class="text-right">Authorized Signature</h3>
            </v-col>
            <h4 class="ml-5" style="color: teal">
              Thank You For Your Business
            </h4>
          </v-row>
          <v-row v-if="approval" class="print">
            <v-col class="d-flex justify-center">
              <v-btn color="primary" elevation="2" @click="submit">
                Approve
              </v-btn>
              <v-btn class="ml-4" color="primary" elevation="2" @click="print">
                Print
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="otpDialog" max-width="450px">
      <otp :quotation="$props.item" @otpVerified="otpVerified" />
    </v-dialog>
  </section>
</template>
<script>
import util from "@/util/util";
import otp from "@/components/Quotation/otp-screen";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taxs: [],
      basic: util.input.basic,
      approval: false,
      otpDialog: false,
      form: {
        products: [],
      },
      showOtp: 0,
    };
  },
  components: { otp },
  mounted() {
    this.getTaxes();
    if (
      this.$route.params.approve &&
      this.$route.params.approve == "approval"
    ) {
      this.approval = true;
      this.getSetting();
    }
  },
  methods: {
    getSetting() {
      util
        .http({
          url: "public/quotation-otp",
          method: "get",
        })
        .then((res) => {
          this.showOtp = res.data.data;
        });
    },
    print() {
      window.print();
    },
    getTaxes() {
      util
        .http({
          url: "tax/public",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.taxs = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    getName(tax) {
      let a = this.taxs.find((t) => t.id == tax.id);
      if (a) {
        return a.name;
      }
    },
    getTaxAmount(tax) {
      let a = this.taxs.find((t) => t.id == tax.id);
      if (a) {
        return (this.subTotal * a.percentage) / 100;
      }
    },
    changeDateFormat(date) {
      return util.changeDateFormat(date);
    },
    submit() {
      if (this.showOtp == 1) {
        this.otpDialog = true;
      }
      this.otpVerified();
    },
    otpVerified() {
      this.otpDialog = false;
      util
        .http({
          url: "/quotation/public/approve/" + this.$props.item.id,
          method: "post",
          data: this.form,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            util.notify(1, "Approved");
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },
  },
  computed: {
    subTotal() {
      let total = 0;
      this.$props.item.products.forEach((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },
    taxTotal() {
      let total = 0;
      this.$props.item.tax.forEach((tax) => {
        total += this.getTaxAmount(tax);
      });
      return total;
    },
    grandTotal() {
      return this.subTotal - this.$props.item.discount + this.taxTotal;
    },
  },
};
</script>
<style scoped>
.v-dialog div {
  background: #fff;
}
@media print {
  .print {
    display: none;
  }
}
</style>
