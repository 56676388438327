<template>
  <v-dialog v-model="$store.state.showRenameDialog" width="500">
    <v-card>
      <v-card-title
        class="text-h5"
        style="background-color: #0c2759; color: white"
      >
        Rename
      </v-card-title>
      <v-col cols="12">
        <v-text-field
          label="Name"
          placeholder="Name"
          v-model="data.name"
          :rules="[rules.required]"
          type="text"
        ></v-text-field>
      </v-col>
      <v-card-text>
        <v-btn block color="primary" @click="renameFile"> Rename</v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    file: {
      required: true,
    },
  },
  data() {
    return {
      data: {
        name: this.$props.file.name,
      },
      rules: util.validation,
    };
  },
  methods: {
    renameFile() {
      let name =
        this.$props.file.name.lastIndexOf(".") != -1
          ? this.data.name + "." + this.$props.file.name.split(".").pop()
          : this.data.name;
      this.$emit("updateName", this.$props.file, name);
    },
  },
  watch: {
    "$props.file": function() {
      if (this.$props.file.name.lastIndexOf(".") != -1) {
        this.data.name = this.$props.file.name.substr(
          0,
          this.$props.file.name.lastIndexOf(".")
        );
      } else {
        this.data.name = this.$props.file.name;
      }
    },
  },
};
</script>
<style scoped>
.add-row-button {
  color: #fff !important;
  background: green !important;
  margin-top: -1rem;
  /* margin-bottom: 1rem; */
  width: 100%;
}

.delete-row {
  cursor: pointer;
}

.delete-row-btn {
  padding-top: 30px;
}

.text-align-center {
  text-align: center;
}
</style>
