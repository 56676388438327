<template>
  <v-card class="">
    <v-col cols="12" md="12">
      <pop-up-layout title="Add Tags">
        <v-row>
          <v-col md="6">
            <h2 style="display: inline">Manage Tags</h2>
          </v-col>
          <!-- <v-col md="6">
        <v-btn class="mx-2 pull-right" fab small color="dangerous">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-col> -->
        </v-row>
        <!-- </v-container> -->

        <v-card-text>
          <!-- <v-container> -->
          <!-- <v-row>
        <h3>Tags:</h3>
        <v-spacer></v-spacer>
        <v-btn>X</v-btn>
      </v-row> -->

          <v-row class="mt-5">
            <v-col class="d-flex" cols="12" sm="12" md="12" :key="key">
              <v-combobox
                class="mb-5"
                v-bind="basic"
                v-model="data"
                :items="tags"
                item-text="name"
                item-value="id"
                :search-input.sync="search"
                hide-selected
                label="Add some tags"
                multiple
                persistent-hint
                small-chips
                clearable
                :rules="[rules.required]"
              >
              </v-combobox>
            </v-col>

            <v-col cols="6">
              <v-btn
                :block="true"
                class="success"
                @click="showCreateDialog = true"
                >Add New Tags</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn :block="true" class="primary" @click="assignTags()"
                >Update Tags</v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center"> </v-col>
          </v-row>
        </v-card-text>
      </pop-up-layout>
    </v-col>

    <v-dialog v-model="showCreateDialog" max-width="600px">
      <createTag />
    </v-dialog>
  </v-card>
</template>
<script>
import util from "@/util/util";
import PopUpLayout from "../../PopUpLayout.vue";
import createTag from "@/components/tags/create";
export default {
  components: { PopUpLayout, createTag },
  props: ["customerId"],
  data() {
    return {
      rules: util.validation,
      key: 0,
      tags: [],
      showCreateDialog: false,
      data: [],
      search: "",
      basic: util.input.basic,
    };
  },
  mounted() {
    this.fetchTags();
    this.fetchAssignedTags();
    util.event.$on("tag-stored", () => {
      this.key++;
      this.fetchTags();
      this.showCreateDialog = false;
    });
  },
  methods: {
    fetchTags() {
      util
        .http({
          url: "tag/get-all",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.tags = res.data.data;
          }
        });
    },
    fetchAssignedTags() {
      util
        .http({
          url: "customer/" + this.customerId + "/tag/",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.data = res.data.data;
          }
        });
    },
    assignTags() {
      let tag_ids = this.data.map((t) => {
        return t.id;
      });
      util
        .http({
          url: "customer/tag",
          method: "post",
          data: {
            tag_id: tag_ids,
            customer_id: this.customerId,
          },
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.event.$emit("refreshResource", true);
            util.notify(1, "Tags assigned");
          }
        });
    },
  },
};
</script>
