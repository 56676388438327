<template>
  <!-- <template #form> -->
  <v-card class="">
    <v-col cols="12" md="12">
      <PopUpLayout :title="edit ? 'Edit Note' : 'ADD NOTES'">
        <!-- <h3>Add Notes:</h3> -->

        <v-text-field
          class="mb-5"
          v-bind="basic"
          label="Note Title"
          v-model="data.title"
        />
        <v-textarea
          v-bind="basic"
          v-model="data.description"
          class="mb-5"
          outlined
          name="input-7-4"
          label="Add Notes"
          value=""
          :rules="[rules.required]"
        ></v-textarea>
        <v-btn depressed color="primary" elevation="2" @click="addNote()"
          >Submit</v-btn
        >
      </PopUpLayout>
    </v-col>
  </v-card>
  <!-- </template> -->
</template>
<script>
import util from "@/util/util";
import PopUpLayout from "../../PopUpLayout.vue";
export default {
  components: { PopUpLayout },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    note: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    rules: util.validation,
    data: {},
    basic: util.input.basic,
    edit: false,
  }),
  mounted() {
    if (Object.entries(this.$props.note).length === 0) {
      this.edit = false;
    } else {
      this.edit = true;
      this.data = this.$props.note;
    }
  },
  methods: {
    addNote() {
      this.data.customer_id = this.$props.customer.id;
      let url = this.edit ? "note/" + this.data.id : "note";
      util
        .http({
          url: url,
          method: this.edit ? "put" : "post",
          data: this.data,
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            if (this.edit) {
              util.notify(1, "Note Updated");
            } else {
              util.notify(1, "Note Stored");
            }
            this.data = {};
            this.$emit("note-success", true);
          }
        });
    },
  },
};
</script>
