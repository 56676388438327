<template>
  <div>
    <!-- <template1 /> -->
  <v-row>
    <v-col cols="6" class="px-0">
      <div style="max-height: 100vh;">
        <template1 :item="item" name="quotation" />
      </div>
    </v-col>
    <!-- <v-col cols="6" class="px-0">
      <div style="max-height: 100vh; overflow-y: auto">
        <template2 :item="item" name="quotation"></template2>
      </div>
    </v-col> -->
  </v-row>
  </div>
</template>

<script>
import util from "@/util/util";
import template1 from "@/components/Quotation/template/quotation1";
// import template2 from "@/components/Quotation/template/quotation2";

export default {
  data: () => {
    return {
      item: {},
    };
  },
  components: {
     template1,
      // template2
       },
  mounted() {
    this.fetchItem();
  },
  methods: {
    headingTransformer: function(val) {
      return util.camelCase(val);
    },
    fetchItem() {
      util
        .http({
          url: "quotation/1",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.item = res.data.data;
            // this.templateId = this.item.template_id;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    additionalColumnsTransformer() {
      return {
        full_name: (row) => {
          return [
            {
              item:
                row.first_name + " " + row.middle_name + " " + row.last_name,
              handler: () => null,
            },
          ];
        },
      };
    },
    rowClicked(row) {
      this.$router
        .push({ name: "single-contact", params: { id: row.id } })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.qt {
  .primary-color {
    color: #1d2b58;
  }
  .primary-bg {
    background: #1d2b58;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid grey;
    text-align: left;
    padding: 15px;
  }

  .clip-path-2 {
    clip-path: polygon(0 0, 31% 0, 0 99%, 0% 100%);
    background: #1d2b58;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 430px;
    width: 340px;
  }
  .clip-path-1 {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    background: #1d2b58;
  }
  .clip-path-5 {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 65% 100%);
    background: #1d2b58;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 430px;
    width: 300px;
  }
  .clip-path-4 {
    background: #1d2b58;
    position: absolute;
    right: 0;
    top: 240px;
    height: 850px;
    width: 40px;
  }
  .title-invoice {
    font-size: 50px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
