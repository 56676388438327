<template>
  <form @submit.prevent="submit">
    <pop-up-layout title="Edit Task">
      <v-text-field
        v-bind="basic"
        class="mt-6"
        label="Name"
        value="Name"
        outlined
      ></v-text-field>

      <v-textarea
        v-bind="basic"
        class="my-5"
        outlined
        name="input-7-4"
        label="Description"
        value=""
      ></v-textarea>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="pb-3"
            v-model="date"
            label="Start Date"
            append-icon="mdi-calendar"
            readonly
            v-bind="{ ...attrs, ...basic }"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="pb-3"
            v-model="date"
            label="End Date"
            append-icon="mdi-calendar"
            readonly
            v-bind="{ ...attrs, ...basic }"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-combobox
        v-model="select1"
        :items="states"
        label="Priority"
        multiple
        v-bind="basic"
        chips
        outlined
      >
        <template v-slot:selection="data">
          <v-chip
            :key="JSON.stringify(data.item)"
            v-bind="{ ...data.attrs, ...basic }"
            :input-value="data.selected"
            :disabled="data.disabled"
            @click:close="data.parent.selectItem(data.item)"
          >
            <v-avatar
              class="accent white--text"
              left
              v-text="data.item.slice(0, 1).toUpperCase()"
            ></v-avatar>
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>

      <v-combobox
        class="my-3"
        v-model="select3"
        :items="list"
        label="Status"
        v-bind="basic"
        multiple
        chips
        outlined
      >
        <template v-slot:selection="data">
          <v-chip
            :key="JSON.stringify(data.item)"
            v-bind="{ ...data.attrs, ...basic }"
            :input-value="data.selected"
            :disabled="data.disabled"
            @click:close="data.parent.selectItem(data.item)"
          >
            <v-avatar
              class="accent white--text"
              left
              v-text="data.item.slice(0, 1).toUpperCase()"
            ></v-avatar>
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>

      <v-text-field
        class="mb-3"
        label="Created By"
        value="Created By"
        v-bind="basic"
        outlined
        disabled
      ></v-text-field>

      <v-btn class="mr-4" type="submit" :disabled="invalid">
        Save
      </v-btn>
    </pop-up-layout>
  </form>
</template>

<script>
import util from "../../util/util";
import PopUpLayout from "../PopUpLayout.vue";

export default {
  components: { PopUpLayout },
  props: ["statusName"],
  data() {
    return {
      list: ["1", "2", "3"],
      select3: this.$props.statusName ? [this.$props.statusName] : null,
      basic: util.input.basic,
    };
  },
};
</script>
<style scoped></style>
