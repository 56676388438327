<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <router-view/> -->
    <v-app>
      <router-view />
      <!-- <Footer/> -->
      <uploader />
      <googleUploader />
      <DropboxUploader />
    </v-app>
    <div class="loader" v-if="$store.state.loader">
      <Loader />
    </div>
    <notifications group="custom-template" position="bottom right">
      <template slot="body" slot-scope="props">
        <div :class="props.item.type">
          <div class="custom-template">
            <div class="custom-template-icon" v-if="props.item.type == 'error'">
              <i class="fa fa-exclamation-circle"></i>
            </div>
            <div class="custom-template-icon" v-else>
              <i class="fa fa-check-circle"></i>
            </div>
            <div class="custom-template-content">
              <div class="custom-template-title">
                {{ props.item.title }}
              </div>
              <div class="custom-template-text" v-html="props.item.text"></div>
            </div>
            <div class="custom-template-close" @click="props.close">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<style lang="scss"></style>

<script>
// import Footer from '@/components/Footer'
import util from "@/util/util";
import Loader from "./components/shared/loader";
import uploader from "@/components/File/OneDrive/uploader";
import googleUploader from "@/components/File/GoogleDrive/uploader";
import DropboxUploader from "@/components/File/Dropbox/uploader";

export default {
  name: "App",

  components: {
    Loader,
    uploader,
    googleUploader,
    DropboxUploader,
  },
  data: () => ({
    //
  }),
  mounted() {
    if (this.$route.name !== "approve-quotation") {
      this.getBasicSetUpData();
    }
  },
  methods: {
    getBasicSetUpData() {
      util
        .http({
          url: "basic-set-up-data",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.$store.commit("setBasicSetUpData", resp.data.data);
          }
        })
        .catch();
    },
  },
};
</script>
<style lang="scss" scoped>
.custom {
  margin: auto;
  width: 100%;
  padding: 10px;
}

.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.success {
  background: #68cd86;
  border-left-color: #42a85f;
}

.notification.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  color: #495061;
  background: #eaf4fe;
  border: 1px solid #d4e8fd;
  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
    color: #2589f3;
  }
}

.custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &,
  & > div {
    box-sizing: border-box;
  }

  .custom-template-icon {
    flex: 0 1 auto;
    font-size: 32px;
    padding: 0 10px;
    color: #fff;
  }

  .custom-template-close {
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    color: #000;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .custom-template-content {
    font-family: "Raleway", sans-serif !important;
    padding: 12px;
    flex: 1 0 auto;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      font-weight: 600;
    }
    .custom-template-text {
      letter-spacing: 1px;
      font-size: 12px;
      color: #fff;
      font-weight: 600;
    }
  }
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}

.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}

label {
  display: block;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0.1em;
  line-height: 2.5em;
}
.form-input-me {
  display: inline-block;
  width: 100%;
}
</style>
<style lang="scss">
.form-wrapper {
  background-color: #dedde0;
  .sub-title {
    background-color: #0c2759;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  .submit-button {
    color: white;
    background-color: green;
  }
}
</style>
