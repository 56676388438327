<template>
  <div class="mt-0">
    <component-layout :title="project.name">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title v-ripple>ADD</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title v-ripple @click="editProject">
                EDIT</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title v-ripple>DELETE</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-space-between align-center">
          <div>Name</div>
          <div>:</div>
        </div>
      </div>
      <div class="col-md-8">{{ project.name }}</div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-space-between align-center">
          <div>Start Date</div>
          <div>:</div>
        </div>
      </div>
      <div class="col-md-8">{{ project.start_date }}</div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-space-between align-center">
          <div>End Date</div>
          <div>:</div>
        </div>
      </div>
      <div class="col-md-8">{{ project.end_date }}</div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-space-between align-center">
          <div>Priority</div>
          <div>:</div>
        </div>
      </div>
      <div class="col-md-8">{{ project.priority }}</div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-space-between align-center">
          <div>Status</div>
          <div>:</div>
        </div>
      </div>
      <div class="col-md-8">{{ project.status }}</div>
    </div> -->
      <div class="my-10">
        <div class="row align-center">
          <v-icon class="primary-color mr-2">mdi-calendar-month</v-icon>
          <div>
            <span class="primary-color">Project Date</span>
            <h4>{{ project.start_date }} to {{ project.end_date }}</h4>
          </div>
        </div>

        <div class="row align-center mt-10">
          <div class="row">
            <v-icon class="primary-color mr-2">mdi-priority-high</v-icon>
            <div>
              <span class="primary-color">Priority</span>
              <h4>{{ project.priority }}</h4>
            </div>
          </div>

          <div class="row mt-0">
            <v-icon class="primary-color mr-2">mdi-list-status</v-icon>
            <div>
              <span class="primary-color">Status</span>
              <h4>{{ project.status }}</h4>
            </div>
          </div>
        </div>
        <div class="row my-10">
          <span class="primary-color pb-2"
            ><v-icon class="primary-color mr-2">mdi-account-group</v-icon
            >Assigned Users:</span
          >

          <template>
            <div>
              <v-combobox
                v-model="project.assignees"
                :items="project.assignees"
                chips
                label="Your favorite hobbies"
                multiple
                solo
                disabled
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                  >
                    <strong>{{ item.user.full_name }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </template>
        </div>
      </div>
      <v-dialog v-model="showEditForm" max-width="800px">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <CreateProject :edit="project" />
            </div>
          </div>
        </div>
      </v-dialog>
    </component-layout>
  </div>
</template>
<script>
import Rest from "@/components/shared/rest";
import componentLayout from "../component-layout.vue";
import CreateProject from "./CreateProject.vue";
export default {
  components: { componentLayout, CreateProject },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      project: {},
      showEditForm: false,
    };
  },
  mounted() {
    this.getProject();
  },
  methods: {
    editProject() {
      this.showEditForm = true;
    },
    getProject() {
      Rest.getSingle.call(this, {
        resource: "project",
        id: this.id,
        prefix: "tm",
      });
      this.assignees = this.project.assignees;
    },
  },
  watch: {
    "$props.id": function () {
      this.getProject();
    },
  },
};
</script>

<style lang="scss">
.project-name-title {
  background: #1d2b58;
  color: #fff;
  padding: 10px 10px !important;
}
.primary-color {
  color: #1d2b58;
}
</style>
