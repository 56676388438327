var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"justify-space-between align-center pb-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(this.$props.TaskTypeName))]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-list',[_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}]},'v-list-item',attrs,false),on),[_vm._v(" ADD ")])]}}]),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"px-5 py-5"},[_c('AddTask',{attrs:{"statusName":this.$props.TaskTypeName}})],1)],1),_c('v-dialog',{attrs:{"width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}]},'v-list-item',attrs,false),on),[_vm._v(" EDIT ")])]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',{staticClass:"px-5 py-5"},[_c('EditTask',{attrs:{"statusName":this.$props.TaskTypeName}})],1)],1),_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"color":"#000"},attrs:{"to":"/task/create-board"}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}]},[_vm._v(" DELETE ")])],1)],1)],1)],1),_c('div',{staticClass:"bg-content",style:(_vm.cssVars)},[_c('div',{staticClass:"py-5"},[_c('TaskUnit',{attrs:{"color":this.$props.color}})],1),_c('div',{staticClass:"py-5"},[_c('TaskUnit',{attrs:{"color":this.$props.color}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }