<template>
  <div style="width: 100%">
    <v-card class="mx-auto mt-5 card" style="border-radius: 10px; width: 95%">
      <v-card-text>
        <v-row class="px-3">
          <div class="d-flex">
            <span class="primary-bg mr-3" style="width: 40px">
              <v-icon color="white">{{
                notification.data.channel == "mail" ? "mail" : "sms"
              }}</v-icon>
            </span>
            <div>
              <p class="text-h6 mt-1 mb-0 text--primary">
                {{ notification.id }}
              </p>
              <v-chip small color="green" text-color="white">{{
                notification.data.channel
              }}</v-chip>
              <div>
                <span
                  ><b>Sent On:</b>
                  {{ parseDate(notification.created_at) }}</span
                >
              </div>
              <div class="mb-3">
                <p class="mb-0">
                  <b>Subject:</b> {{ notification.data.subject }}
                </p>
              </div>
              <div v-html="notification.data.content"></div>
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>

<style scoped>
.card {
  background: #f8f8f8 !important;
}
.primary-bg {
  background: #1d2b58;
  border-radius: 50%;
  height: 40px;
  width: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
