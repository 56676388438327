<template>
  <div class="project-boards" style="border-bottom: 2px solid #1d2b58">
    <component-layout title="Boards">
      <!-- <v-icon color="white">mdi-dots-vertical</v-icon> -->
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ name: 'CreateBoard', params: { project } }"
            > -->
            <v-list-item v-ripple="{ center: true }" @click="addBoard">
              ADD
            </v-list-item>
            <!-- </router-link> -->
          </v-list>
        </v-menu>
      </template>

      <div class="mt-5">
        <v-treeview
          v-if="project"
          v-model="tree"
          :open="initiallyOpen"
          :items="computedItems"
          activatable
          item-key="id"
          open-on-click
          dense
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="'parent_id' in item && item.parent_id"
              >mdi-calendar-check-outline</v-icon
            >
            <v-icon v-else> mdi-{{ item.icon }} </v-icon>
          </template>

          <template #label="{ item }">
            <div>{{ item.name }}</div>
          </template>
          <template #append="{ item }">
            {{ item.id }}
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-auto bg-btn"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="black">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item
                    @click="itemClicked(item, lItem)"
                    dense
                    v-for="(lItem, i) in item.context_action"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon color="black">{{ lItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="lItem.text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-treeview>
      </div>
      <v-dialog v-model="showAddForm" max-width="800px">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <!-- <CreateProject :edit="clickedItem" /> -->
              <CreateBoard :project="project" v-if="project" />
            </div>
          </div>
        </div>
      </v-dialog>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "../component-layout.vue";
import CreateBoard from "./CreateBoard.vue";
export default {
  components: { componentLayout, CreateBoard },
  props: {
    id: {
      required: true,
    },
  },
  mounted() {
    this.getBoards();
  },
  methods: {
    addBoard() {
      this.showAddForm = true;
    },
    getBoards() {
      util
        .http({
          url: "tm/project/" + this.id + "/board",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.project = resp.data.data;
          }
        });
    },
  },
  watch: {
    "$props.id": function () {
      this.getBoards();
    },
  },
  computed: {
    computedItems() {
      return util.constructTree(this.project.boards);
    },
  },

  data: () => ({
    project: null,
    initiallyOpen: ["public"],
    showAddForm: false,
    tree: [],
  }),
};
</script>

<style scoped lang="scss">
.home-hover:hover {
  .icon {
    display: block;
  }
}
.home-hover {
  .icon {
    display: none !important;
  }
}
</style>
