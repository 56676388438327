<template>
  <div class="py-5">
    <div class="d-flex">
      <v-select
        v-if="hasFilter"
        v-bind="basic"
        class="mr-3"
        style="width: 50px"
        label="Filter By"
        v-model="filterCriteria.status"
        :items="['Active', 'Inactive']"
      ></v-select>
      <v-text-field
        class="mr-3"
        v-bind="basic"
        style="width: 50px"
        label="Search"
        append-icon="search"
        value=""
        outlined
        v-model="filterCriteria.q"
      ></v-text-field>

      <slot name="menu"></slot>
    </div>
    <slot></slot>

    <br />
    <v-pagination
      v-if="hasPagination"
      :length="filterCriteria.length"
      v-model="filterCriteria.page"
    ></v-pagination>
  </div>
</template>
<script>
import util from "@/util/util";

export default {
  
  props: {
    hasFilter: {
      type: Boolean,
      default: true,
    },
    length: {
      type: Number,
    },
    filterCriteriaProps: {
      type: Object,
      default: () => {
        return {
          q: "",
          page: 1,
          length: 2,
        };
      },
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      basic: util.input.basic,
      filterCriteria: this.filterCriteriaProps,
    };
  },
  watch: {
    filterCriteria: {
      handler(n) {
        this.$emit("filter", n);
      },
      deep: true,
    },
    length: function(n) {
      this.filterCriteria.length = n;
    },
  },
};
</script>
<style scoped>
.bg-btn {
  background: #1d2b58 !important;
}
</style>
