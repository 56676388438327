<template>
  <div>
    <gantt-elastic :tasks="tasks" :options="options">
      <gantt-elastic-header slot="header"></gantt-elastic-header>
    </gantt-elastic>
  </div>
</template>

<script>
import util from "@/util/util";
import GanttElastic from "gantt-elastic";
import GanttElasticHeader from "gantt-elastic-header";
export default {
  components: {
    ganttElasticHeader: GanttElasticHeader, // or Header
    ganttElastic: GanttElastic,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tasks: [],

      options: {
        maxRows: 100,
        maxHeight: 300,
        title: {
          label: "",
          html: false,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: false,
          },
        },
        chart: {
          progress: {
            bar: true,
          },
          expander: {
            display: false,
          },
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
            {
              id: 1,
              label: "ID",
              value: "id",
              width: 40,
            },
            {
              id: 2,
              label: "Description",
              value: "description",
              width: 330,
              expander: true,
            },
            {
              id: 3,
              label: "Title",
              value: "title",
              width: 100,
            },
            {
              id: 5,
              label: "Start",
              value: (task) => util.changeDateFormat(task.start_date),
              width: 90,
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.fetchTasks();
  },
  methods: {
    fetchTasks() {
      util
        .http({
          url: "tm/project/" + this.$props.projectId,
        })
        .then((res) => {
          if (res.data.status == 1) {
            res.data.data.boards.map((board) => {
              board.tasks.map((task) => {
                task.label = task.title;
                task.parentId = task.parent_id;
                task.progress = 50;
                task.start = new Date(task.start_date);
                task.duration = Math.abs(new Date(task.end_date) - task.start);
                task.type = "task";
                task.style = {
                  fill: "#1EBC61",
                  stroke: "#0EAC51",
                };
                task.progressBarStyle = {
                  bar: {
                    fill: "#0EAC51",
                  },
                };
                this.tasks.push(task);
              });
            });
          }
        });
    },
  },
};
</script>
