<template>
  <div>
    <google
      clientId="395276949039-0s9egduqdrgcc2korumqnvi9vircqrta.apps.googleusercontent.com"
      appId="395276949039"
    />
  </div>
</template>
<script>
import google from "../google-drive.vue";
export default {
  components: { google },
};
</script>
