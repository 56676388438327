<template lang="">
  <border-box>
    <componentLayout title="Project List">
       <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ name: 'CreateBoard', params: { project } }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD
              </v-list-item></router-link
            > -->
          </v-list>
        </v-menu>
      </template>
      <!-- <v-row class="d-flex align-center mb-8 px-3">
        <v-select
          v-bind="basic"
          class="mr-3"
          style="width: 50px"
          :items="filterCriteriaList"
          label="Filter Project By"
          v-model="filterCriteria.status"
        ></v-select>
        <v-text-field
          v-bind="basic"
          style="width: 50px"
          label="Search"
          append-icon="search"
          value=""
          outlined
          v-model="filterCriteria.q"
        ></v-text-field>
      </v-row> -->
      

      <div class="row">
        <div
          class="col-md-6 mb-2"
          v-for="project in projects"
          :key="project.id"
        >
          <!-- <border-box> -->
          <router-link
            class="text-decoration-none"
            :to="{ name: 'ProjectDetail', params: { id: project.id } }"
          >
            <v-row class="mx-2" style="border: 1px solid #000">
              <v-col class="p-0" cols="4" sm="4" md="4" lg="4">
                <v-avatar color="#0C2759" size="56">
                  <span
                    class="white--text text-h5"
                    style="text-transform:uppercase"
                  >
                    {{ project.name.substr(0, 1) }}
                  </span>
                </v-avatar>
              </v-col>
              <v-col class="p-0" cols="8" sm="8" md="8" lg="8">
                <h6
                  class="text-uppercase"
                  style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"
                  :title="project.name"
                >
                  {{ project.name }}
                </h6>
                <span class="text--disabled"
                  ><v-icon small color="darken-2"> event </v-icon
                  >{{ project.start_date }}</span
                >
              </v-col>
            </v-row>
          </router-link>
          <!-- </border-box> -->
        </div>
       
        <v-pagination
          :length="filterCriteria.length"
          v-model="filterCriteria.page"
          @input="paginationClicked"
        ></v-pagination>
        
      </div>
      <!-- </div> -->

      <!-- <router-link to="/task/new-project"> -->
        <button class="btn create-project-btn" @click="createNewProject">Create New Project +</button>
      <!-- </router-link> -->
      <v-dialog v-model="showCreateForm" max-width="800px">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <CreateProject  />
            </div>
          </div>
        </div>
    </v-dialog>
    </componentLayout>
  </border-box>
</template>
<script>
import borderBox from "@/components/Quotation/border-box.vue";
import componentLayout from "@/components/component-layout.vue";
import util from "@/util/util";
import Rest from "@/components/shared/rest";
import CreateProject from "@/components/Project/CreateProject.vue";

export default {
  components: {
    componentLayout,
    CreateProject,
    borderBox,
  },
  data() {
    return {
      showCreateForm: false,
      filterCriteriaList: [],
      basic: util.input.basic,
      projects: [],
      filterCriteria: {
        length: 0,
        page: 1,
        q: "",
        status: "",
      },
    };
  },
  mounted() {
    this.getProjects();
    this.getProjectFilters();
  },
  methods: {
    createNewProject() {
      this.showCreateForm = true;
    },
    getProjectFilters() {
      util
        .http({
          url: "tm/project/list-filter",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.filterCriteriaList = resp.data.data;
          }
        });
    },
    paginationClicked(page) {
      this.filterCriteria.page = page;
    },
    getProjects() {
      Rest.get
        .call(this, {
          resource: "project",
          prefix: "tm",
          params: this.filterCriteria,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.filterCriteria.length = res.data.data.last_page;
          }
        });
    },
  },
  watch: {
    filterCriteria: {
      deep: true,
      handler: function () {
        this.getProjects();
      },
    },
  },
};
</script>
<style scoped>
.project-img {
  width: 50px;
  height: 50px;
}
.row {
  padding: 0px !important;
}
.create-project-btn {
  background: #1d2b58;
  width: 100%;
  color: #fff;
  padding: 10px;
  margin-top: 50px;
}
</style>
