<template>
  <Layout @filter="filter" :filterCriteriaProps="filterCriteria">
    <template #menu>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item style="cursor: pointer">
            <v-list-item-title v-ripple @click="onButtonPress()"
              >ADD</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-dialog v-model="Notify" max-width="600px">
      <email-form :customer="customer" @sent="mailSent" />
    </v-dialog>
    <div
      class="row mt-5"
      v-for="notification in notifications"
      :key="notification.id"
    >
      <NotifyCard :notification="notification" />
    </div>

    <!-- <div class="row">
      <NotifyCard />
    </div> -->
  </Layout>
</template>

<script>
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
import NotifyCard from "@/components/Contact/PersonalContactsInfos/NotifyCard.vue";
import EmailForm from "../PopupForms/EmailForm.vue";
import Rest from "@/components/shared/rest";

export default {
  components: { Layout, NotifyCard, EmailForm },

  data() {
    return {
      Notify: false,
      customer: null,
      notifications: [],
      filterCriteria: { length: 1, page: 1, q: "" },
    };
  },
  mounted() {
    this.fetchCustomer();
    this.fetchNotifications();
  },
  methods: {
    filter(n) {
      this.filterCriteria = n;
    },
    onButtonPress() {
      this.Notify = true;
    },
    fetchNotifications() {
      Rest.get
        .call(this, {
          resource: "notification",
          prefix: "customer/" + this.$route.params.id + "",
          params: this.filterCriteria,
        })
        .then((resp) => {
          this.filterCriteria.length = resp.data.data.last_page;
          console.log(resp);
        });
    },
    fetchCustomer() {
      return Rest.getSingle.call(this, {
        resource: "customer",
        id: this.$route.params.id,
      });
    },
    mailSent() {
      this.Notify = false;
      this.fetchNotifications();
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.fetchNotifications();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.bg-btn {
  background: #1d2b58 !important;
  color: white !important;
}
</style>
