<template>
  <div>
    <component-layout :title="(edit ? 'Edit' : 'Create') + ' Project'">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title v-ripple>ADD</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title v-ripple>
                <router-link
                  style="color: #000"
                  class="text-decoration-none"
                  to=""
                  >EDIT
                </router-link></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title v-ripple>DELETE</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-form @submit.prevent="submit" v-model="isValid" ref="form">
        <v-text-field
          v-bind="basic"
          class="mt-6"
          label="Name"
          value="Name"
          v-model="data.name"
          outlined
          :rules="[rules.required]"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-5"
              v-model="data.start_date"
              label="Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="{ ...attrs, ...basic }"
              v-on="on"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.start_date"
            no-title
            scrollable
            @change="menu1 = false"
          />
        </v-menu>

        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-5"
              v-model="data.end_date"
              label="End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="{ ...attrs, ...basic }"
              v-on="on"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.end_date"
            no-title
            scrollable
            @change="menu2 = false"
          />
        </v-menu>

        <v-select
          v-bind="basic"
          class="mt-5"
          v-model="data.priority"
          :items="priorityOptions"
          label="Priority"
          chips
          :rules="[rules.required]"
          outlined
        />

        <v-select
          v-bind="basic"
          class="mt-5"
          v-model="data.status"
          :items="['Active', 'Inactive']"
          label="Status"
          :rules="[rules.required]"
          chips
          outlined
        />

        <v-combobox
          v-bind="basic"
          class="mt-5"
          v-model="data.users"
          :items="users"
          :rules="[rules.required]"
          item-text="full_name"
          item-value="id"
          label="Assign Users"
          multiple
          chips
          outlined
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              {{ data.item.full_name }}
            </v-chip>
          </template>
        </v-combobox>
        <v-col>
          <div class="mt-5">
            <v-btn class="mr-4" type="submit" :disabled="false"> Save </v-btn>
            <v-btn @click="clear"> clear </v-btn>
          </div>
        </v-col>
      </v-form>
    </component-layout>
  </div>
</template>

<style scoped>
.head {
  border-bottom: 3px solid #1d2b58;
}
.primary-bg {
  background: #1d2b58;
}
</style>

<script>
import util from "../../util/util";
import ComponentLayout from "../component-layout.vue";
export default {
  components: { ComponentLayout },
  props: {
    edit: {
      type: Object || null,
      default: () => null,
    },
  },
  data() {
    return {
      rules: util.validation,
      isValid: false,
      basic: util.input.basic,
      priorityOptions: util.priorityOptions,
      users: [],
      checkbox: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      modal: false,
      menu2: false,

      data: {
        assignees: null,
        status: null,
        priority: null,
        name: "",
        users: [],
      },
    };
  },
  mounted() {
    if (!this.edit || !this.edit.id) {
      this.data = {
        assignees: null,
        status: null,
        priority: null,
        name: "",
        users: [],
      };
    }
    this.fetchUsers();
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.data.assignees = this.data.users.map((u) => {
          return {
            user_id: u.id,
            start_date: this.data.start_date,
            end_date: this.data.end_date,
            project_role_id: 1,
          };
        });
        let url =
          this.edit && this.edit.id
            ? "/tm/project/" + this.edit.id
            : "/tm/project";
        util
          .http({
            url: url,
            method: this.edit && this.edit.id ? "put" : "post",
            data: this.data,
          })
          .then((res) => {
            if (res.data.status == 1) {
              this.edit.id
                ? util.notify(1, "Project Updated")
                : util.notify(1, "Project Created");
              this.$refs.form.reset();
              this.$refs.form.reset();
              this.data = {
                assignees: null,
                status: null,
                priority: null,
                name: "",
              };
            } else {
              util.notify(0, res.data.errors[0]);
            }
          })
          .catch();
      }
    },
    clear() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
      this.$refs.observer.reset();
    },
    fetchUsers() {
      util
        .http({
          url: "/user/get-all",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.users = res.data.data;
            if (this.edit.id) {
              this.fetchProject();
            }
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchProject() {
      util
        .http({
          url: "tm/project/" + this.edit.id,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.data = res.data.data;
            let users = [];
            this.data.assignees.map((u) => {
              this.users.some(function (user) {
                if (user.id === u.user_id) {
                  users.push(user);
                }
              });
            });
            this.data.users = users;
          }
        });
    },
  },
  watch: {
    edit: {
      handler() {
        if (this.edit && this.edit.id) {
          this.fetchProject();
        }
      },
      deep: true,
    },
  },
};
</script>
