<template>
  <div class="progress-wrapper" v-if="show">
    <v-card outlined>
      <v-card-title class="upload-title">
        Uploading Files
        <span
          class="fa fa-times"
          style="right: 10px; position: absolute; cursor: pointer"
          title="Hide Upload Panel"
          @click="show = false"
        >
        </span>
      </v-card-title>

      <div class="container">
        <div
          class="row"
          style="margin: 0px"
          v-for="file in files"
          :key="file.id"
        >
          <div class="col-md-10" style="padding: 0px">
            <v-progress-linear
              v-model="file['progress']"
              color="blue"
              height="20"
            >
              <template v-slot:default="{ value }">
                <strong style="color: white; font-size: 10px"
                  >{{ file.name + "--" + Math.ceil(value) }}%</strong
                >
              </template>
            </v-progress-linear>
          </div>
          <div class="col-md-2" :key="'cross_' + file.id" style="padding: 0px">
            <i v-if="file.cancelRequest" style="font-size: 10px">Cancelled</i>
            <i
              v-else
              @click="cancelUpload(file)"
              style="margin-left: 5px; cursor: pointer"
              title="cancel"
              class="fa fa-times"
            ></i>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  mounted() {
    util.event.$on("filesUploaded", () => {
      this.show = true;
    });
    util.event.$on("dropboxFilesUploaded", () => {
      this.show = true;
    });
    util.event.$on("filesUploadedGoogle", () => {
      this.show = true;
    });
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    cancelUpload(file) {
      this.$emit("cancelUpload", file);
    },
  },
};
</script>
<style lang="scss">
.upload-title {
  padding: 5px !important;
}
</style>
