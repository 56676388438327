<template>
  <div>
    <action :show="$props.show" :edit="$props.edit" :delete="$props.delete">
    </action>
  </div>
</template>
<script>
import action from "@/components/shared/action_buttons.vue";
export default {
  components: { action },
  props: {
    show: {
      type: Object,
    },
    edit: {
      type: Object,
    },
    delete: {
      type: Object,
    },
  },
};
</script>
