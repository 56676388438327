import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Contacts from "../views/Contacts.vue";
import Events from "../views/Events.vue";
import EventList from "@/components/events/list.vue";
import Notification from "../views/Notification.vue";
import TaskManagement from "../views/TaskManagement.vue";
import Reports from "../views/Reports.vue";
import Files from "../views/Files.vue";
import AddContact from "../components/Contact/AddContact.vue";
import ContactView from "../components/Contact/ContactView.vue";
import Board from "../views/Board.vue";
// import CreateBoard from "../views/CreateBoard.vue";
import EditContact from "../components/Contact/EditContact.vue";
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import OTPPage from "../components/Auth/OTPPage";
import CompanySettingList from "../components/CompanySetting/ListCompanySetting";
import CompanySettingEdit from "../components/CompanySetting/EditCompanySetting";
import ProjectDetail from "../components/Project/ProjectDetail.vue";
import CreateProject from "../components/Project/CreateProject.vue";
import ContactTable from "../components/Contact/List.vue";
import AdditionalInformation from "../components/Contact/PersonalContactsInfos/AdditionalInformation.vue";
import ContactNotify from "../components/Contact/PersonalContactsInfos/ContactNotify.vue";
import ContactNotes from "../components/Contact/PersonalContactsInfos/ContactNotes.vue";
import ContactTags from "../components/Contact/PersonalContactsInfos/Tags.vue";
import AddEvent from "../components/events/create.vue";
import google from "@/components/File/GoogleDrive/google-wrapper";
import onedrive from "@/components/File/one-drive";
// import dropbox from "@/components/File/dropbox";
import dropbox from "@/components/File/Dropbox/dropbox-wrapper";

/**
 * Quotation part
 */
import CreateQuotation from "@/components/Quotation/create";
import ListQuotation from "@/components/Quotation";
import print from "@/components/shared/print";

//gantt
import GanttChart from "@/components/Project/gantt-wrapper";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/contacts/add",
        name: "AddContact",
        component: AddContact,
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        children: [
          {
            path: ":id",
            name: "single-contact",
            component: ContactView,
            children: [
              {
                path: "additional-information",
                name: "AdditionalInformation",
                component: AdditionalInformation,
              },
              {
                path: "notify",
                name: "ContactNotify",
                component: ContactNotify,
              },
              {
                path: "notes",
                name: "ContactNotes",
                component: ContactNotes,
              },
              {
                path: "tags",
                name: "ContactTags",
                component: ContactTags,
              },
            ],
          },

          {
            path: "/contacts/:id/edit",
            name: "EditContact",
            component: EditContact,
          },
        ],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: "/contacts-list",
        name: "ContactTable",
        component: ContactTable,
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
      },
      {
        path: "/events/list",
        name: "EventList",
        component: EventList,
      },
      {
        path: "/events/add",
        name: "AddEvent",
        component: AddEvent,
      },
      {
        path: "/notification",
        name: "Notification",
        component: Notification,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/task/board",
        name: "Board",
        component: Board,
      },
      // {
      //   path: "/task/create-board",
      //   name: "CreateBoard",
      //   component: CreateBoard,
      // },
      {
        path: "task/project/:id/gantt-chart",
        name: "chart",
        component: GanttChart,
      },
      {
        path: "/task",
        name: "TaskManagement",
        component: TaskManagement,
        children: [
          {
            path: "new-project",
            name: "CreateProject",
            component: CreateProject,
          },
          {
            path: "project/:id/edit",
            name: "EditProject",
            component: CreateProject,
          },
          {
            path: "project/:id",
            name: "ProjectDetail",
            component: ProjectDetail,
          },
        ],
      },
      {
        path: "/files",
        component: Files,
        children: [
          {
            path: "",
            name: "google",
            component: google,
          },
          {
            path: "google",
            name: "google",
            component: google,
          },
          {
            path: "onedrive",
            name: "onedrive",
            component: onedrive,
          },
          {
            path: "dropbox",
            name: "dropbox",
            component: dropbox,
          },
        ],
      },
      {
        path: "/company-setting",
        name: "CompanySettingList",
        component: CompanySettingList,
      },
      {
        path: "/company-setting/edit/:id",
        name: "CompanySettingEdit",
        component: CompanySettingEdit,
      },
      {
        path: "/quotation/create",
        name: "quotation-create",
        component: CreateQuotation,
      },
      {
        path: "/quotation",
        name: "quotation",
        component: ListQuotation,
      },
      {
        path: "/quotation/:id/edit",
        name: "quotation-edit",
        component: CreateQuotation,
      },
      {
        path: "/po",
        name: "po",
        component: require("@/components/PO/index.vue").default,
      },
      {
        path: "/po/create",
        name: "po-create",
        component: CreateQuotation,
        props: {
          type: "po",
        },
      },
      {
        path: "/po/:id/edit",
        name: "po-edit",
        component: require("@/components/Quotation/create.vue").default,
        props: {
          type: "po",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/otp-page",
    name: "OTPPage",
    component: OTPPage,
    // meta: {auth:false},
  },
  {
    path: "/:item/print/:id",
    name: "print-file",
    component: print,
  },
  {
    path: "/:item/:approve/:id",
    name: "approve-quotation",
    component: print,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let allowedPath = ["Login", "OTPPage", "Register", "approve-quotation"];
router.beforeEach((to, from, next) => {
  let isAuthenticated = sessionStorage.getItem("token");
  if (allowedPath.indexOf(to.name) != -1 && isAuthenticated)
    next({ name: "dashboard" });
  // if (allowedPath.indexOf(to.name) == -1 && !isAuthenticated && !to.meta.auth)
  //     next({ name: "OTPPage" });

  if (allowedPath.indexOf(to.name) == -1 && !isAuthenticated)
    next({ name: "Login" });
  if (to.name == "OTPPage") {
    next();
  } else next();
});

export default router;
