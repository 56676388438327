<template>
  <div v-if="templateId != 0">
    <template1
      :item="item"
      :name="this.$route.params.item"
      v-if="templateId == 1"
    />
    <template2
      :item="item"
      :name="this.$route.params.item"
      v-if="templateId == 2"
    />
    <template3
      :item="item"
      :name="this.$route.params.item"
      v-if="templateId == 3"
    />
  </div>
</template>
<script>
import util from "@/util/util";
import template1 from "@/components/Quotation/template/quotation1";
import template2 from "@/components/Quotation/template/quotation2";
import template3 from "@/components/Quotation/template/quotation3";
export default {
  data: () => {
    return {
      item: {},
      templateId: 0,
    };
  },
  components: {
    template1,
    template2,
    template3,
  },
  mounted() {
    this.fetchItem();
    console.log("loaded");
    window.addEventListener("custom-template-loaded", () => {
      console.log("this si custom event");
    });
    window.dispatchEvent(new Event("custom-template-loaded"));
  },
  methods: {
    fetchItem() {
      let url;
      if (
        this.$route.params.approve &&
        this.$route.params.approve == "approval"
      ) {
        url = `quotation/public/${this.$route.params.id}`;
      } else if (this.$route.params.item == "quotation") {
        url = `quotation/${this.$route.params.id}`;
      } else {
        url = `invoice/${this.$route.params.id}`;
      }
      util
        .http({
          url: url,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.item = res.data.data;
            this.templateId = this.item.template_id;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
