<template>
  <div>
    <component-layout title="Purchase Order">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ path: '/po/create' }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD
              </v-list-item></router-link
            >
          </v-list>
        </v-menu>
      </template>

      <vue-table
        :key="key"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :html="table.html"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :valueTransformer="table.valueTransformer"
        :columnSortOrder="table.columnSortOrder"
      ></vue-table>
      <!-- </div>
      </div> -->
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import action from "@/components/shared/action-quotation.vue";
import ComponentLayout from "../component-layout.vue";
export default {
  components: {
    // borderBox,
    VueTable,
    // list,

    ComponentLayout, // detail,
    // search,
  },
  data() {
    return {
      key: 0,
      quotations: [],
      selectedQuotation: {},
      page: 0,
      last_page: 0,
      searchTerm: "",
      table: {
        url: "po",
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "notes",
          "customer_id",
          "otp",
          "template",
          "template_id",
          "products",
          "files",
          "tax",
        ],
        columnSortOrder: {
          ref_no: 1,
          customer: 2,
          discount: 3,
          total: 4,
          issue_date: 5,
          valid_till: 6,
          status: 8,
          Action: 9,
        },
        html: [
          "Action",
          "customer",
          "issue_date",
          "valid_till",
          "status",
          "total",
          "discount",
        ],
        additionalColumns: ["Action"],
        headingTransformer: function (val) {
          return util.camelCase(val);
        },
        additionalColumnsTransformer: function () {
          return {
            Action: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    data: row,
                    name: "po",
                  },
                },
              ];
            },
          };
        },
        valueTransformer: function () {
          return {
            customer: (row) => {
              return [
                {
                  item: row.customer.full_name,
                  handler: () => null,
                },
              ];
            },
            issue_date: (row) => {
              return [
                {
                  item: util.changeDateFormat(row.issue_date),
                  handler: () => null,
                },
              ];
            },
            valid_till: (row) => {
              return [
                {
                  item: util.changeDateFormat(row.valid_till),
                  handler: () => null,
                },
              ];
            },
            total: (row) => {
              return [
                {
                  item: "$ " + row.total,
                  handler: () => null,
                },
              ];
            },
            discount: (row) => {
              return [
                {
                  item: "$ " + row.discount,
                  handler: () => null,
                },
              ];
            },
            status: (row) => {
              return [
                {
                  item:
                    '<span style="color:white;padding:0.4rem" class="badge ' +
                    (row.status == 0 ? "new badge orange" : "new badge green") +
                    '">' +
                    (row.status == 0 ? "Draft" : "Published") +
                    "</span>",
                  handler: () => null,
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    this.fetchQuotations();
    util.event.$on("item-selected", (item) => {
      this.selectedQuotation = item;
    });
    util.event.$on("item-edit", (id) => {
      this.itemEdit(id);
    });
    util.event.$on("reload-items", () => {
      // this.fetchQuotations();
      this.key++;
    });
  },
  methods: {
    fetchQuotations() {
      let url =
        this.page == 0
          ? "/po?q=" + this.searchTerm
          : "/po?q=" + this.searchTerm + "&page=" + this.page;
      util
        .http({
          url: url,
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.quotations = res.data.data.data;
            this.page = res.data.data.current_page;
            this.last_page = res.data.data.last_page;
          }
        })
        .catch();
    },
    searchQuotation(searchTerm) {
      this.searchTerm = searchTerm;
      this.page = 1;
      this.fetchQuotations();
    },
    itemEdit(id) {
      this.$router.push(`/po/${id}/edit`);
    },
  },
  watch: {
    quotations: function () {
      this.quotations.map((i) => {
        i.issue_date = util.changeDateFormat(i.issue_date);
      });
    },
  },
};
</script>
