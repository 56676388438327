<template>
  <div>
    <div class="mb-3">
      <ProjectName :id="project_id" />
    </div>
    <BoardTree :id="project_id" />
    <router-link to="/task/board">
      <button class="btn create-project-btn">Go To Board</button>
    </router-link>
  </div>
</template>

<script>
import BoardTree from "./BoardTree.vue";
import ProjectName from "./ProjectName.vue";
export default {
  components: {
    BoardTree,
    ProjectName,
  },
  data() {
    return { project_id: this.$route.params.id };
  },
  watch: {
    "$route.params.id": function (n) {
      this.project_id = n;
    },
  },
};
</script>

<style scoped>
.project-name-title {
  background: #1d2b58;
  color: #fff;
  padding: 10px 10px;
}
.create-project-btn {
  background: #1d2b58;
  color: #fff;
  padding: 7px 25px;
  margin-top: 30px;
}
</style>
