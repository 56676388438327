<template>
  <v-card>
    <v-card-title> Create New Tag </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Tag Name"
                v-model="data.name"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Description"
                v-model="data.description"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12">
              <v-btn class="success" :block="true" @click="addTag()">
                Create Tag
              </v-btn>
            </v-col>
          </v-row>
          <v-row :key="'_tag' + key">
            <h2>Available Tags</h2>
            <v-col cols="12">
              <list />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import list from "./index";
import util from "@/util/util";
export default {
  components: { list },
  data() {
    return {
      rules: util.validation,
      data: {},
      key: 0,
    };
  },
  methods: {
    addTag() {
      this.data.contactTypes = this.contactTypes;
      util
        .http({
          url: "tag",
          method: "post",
          data: this.data,
        })
        .then((res) => {
          if (res.data.status == 1) {
            util.notify(1, "Tag Stored");
            util.event.$emit("tag-stored", true);
            this.$refs.form.reset();
            this.key++;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
