<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-toolbar-title class="mb-6">Edit Contact</v-toolbar-title>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="First Name"
              placeholder="Enter First Name"
              v-model="data.first_name"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Middle Name"
              placeholder="Enter Middle Name"
              v-model="data.middle_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Last Name"
              placeholder="Enter Last Name"
              v-model="data.last_name"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Address"
              placeholder="Enter Address"
              v-model="data.address"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="City"
              placeholder="Enter City"
              v-model="data.city"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="stateOptions"
              item-text="label"
              item-value="value"
              label="State"
              v-model="data.state"
              :rules="[rules.required]"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Country"
              placeholder="Enter Country"
              v-model="data.country"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Postal Code"
              placeholder="Enter Postal Code"
              v-model="data.postal_code"
              maxlength="4"
              :rules="[rules.required, rules.number, rules.postalLength]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              :items="companyOptions"
              item-text="company_name"
              item-value="id"
              label="Company"
              v-model="data.company_id"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Job Title"
              placeholder="Enter Job Title"
              v-model="data.job_title"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Email"
              placeholder="Enter Email"
              v-model="data.email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              :items="customerTypeOptions"
              item-text="name"
              item-value="id"
              label="Customer Type"
              v-model="data.customer_type_id"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              :items="statusOptions"
              item-text="label"
              item-value="value"
              label="Status"
              v-model="data.status"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Sort"
              placeholder="Sort"
              v-model="data.sort"
              :rules="[rules.required, rules.number]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              elevation="2"
              large
              v-on:click="updateContact"
              >Update</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import util from "@/util/util";
export default {
  data() {
    return {
      data: {},
      rules: util.validation,
      statusOptions: util.statusOptions,
      stateOptions: util.stateOptions,
      companyOptions: [],
      customerTypeOptions: [],
    };
  },
  mounted() {
    this.fetchContact();
    this.fetchCompanies();
    this.fetchCustomerTypes();
  },
  methods: {
    fetchContact() {
      util
        .http({ url: "customer/" + this.$route.params.id, method: "get" })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.data = res.data.data;
            console.log(res);
          }
        });
    },
    fetchCompanies() {
      util.http({ url: "company/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.companyOptions = res.data.data;
        }
      });
    },
    fetchCustomerTypes() {
      util.http({ url: "customer-type/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.customerTypeOptions = res.data.data;
        }
      });
    },
    updateContact() {
      this.$refs.form.validate();
      util
        .http({
          url: "customer/" + this.$route.params.id,
          method: "put",
          data: this.data,
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.$router.push({ name: "Contacts" });
          }
        });
    },
  },
};
</script>
