<template>
  <div>
    <v-btn
      color="blue"
      class="ma-2 white--text"
      block
      @click="open"
      v-if="!this.$store.state.dropbox_token"
      >Login to Dropbox
      <v-icon right dark> mdi-dropbox </v-icon>
    </v-btn>

    <v-btn
      color="blue"
      class="ma-2 white--text"
      block
      v-if="this.$store.state.dropbox_token && files.length == 0"
      >Empty folder
    </v-btn>

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="deep-purple accent-4"
    ></v-progress-linear>
    <file-list
      :files="computedFiles"
      @fetchFiles="fetchFiles"
      @downloadFile="downloadFile"
      @shareFile="shareFile"
      @move="move"
      @deleteFile="deleteFile"
      @filesUploaded="filesUploaded"
      @renameFile="renameFile"
      v-if="files.length > 0"
    ></file-list>
    <share :file="currentFile" :key="currentFile.id" v-if="currentFile" />
  </div>
</template>
<script>
var Url = require("url-parse");
import axios from "axios";

import fileList from "@/components/File/file-list.vue";
import share from "@/components/File/Dropbox/share.vue";
import util from "@/util/util";
export default {
  components: {
    fileList,
    share,
  },
  props: {
    clientId: {
      type: String,
    },
  },
  data() {
    return {
      files: [],
      baseUrl: "https://www.dropbox.com/oauth2/authorize",
      client_id: "0770fedxgkvi6zf",
      // scope: "files.metadata.read",
      response_type: "token",
      redirect_uri:
        window.location.protocol +
        "//" +
        window.location.host +
        "/files/dropbox",
      currentFile: null,
      loading: false,
    };
  },
  mounted() {
    var url = new Url(window.location.href.replace("#", "?"), true);
    if (url.query.access_token) {
      this.$store.commit("setDropboxToken", url.query.access_token);
      this.$router.push("/files/dropbox");
    }
    if (this.$store.state.dropbox_token) {
      this.fetchFiles({ name: "Root", id: "" });
    }
    util.event.$off("refreshFileList");
    util.event.$on("refreshFileList", () => {
      this.fetchFiles(
        this.$store.state.stack[this.$store.state.stack.length - 1]
      );
    });
  },
  methods: {
    filesUploaded(files, parent) {
      util.event.$emit("dropboxFilesUploaded", files, parent);
    },
    shareFile(file) {
      this.currentFile = file;
      this.$store.commit("toggleDropboxShareDialog", true);
    },
    downloadFile(file) {
      this.loading = true;
      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/files/get_temporary_link",
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
        },
        data: {
          path: file.id,
        },
      })
        .then((res) => window.open(res.data.link))
        .finally(() => {
          this.loading = false;
        });
    },
    fetchFiles(group) {
      this.$store.commit("addToStack", group);
      this.getFiles(group).then((resp) => {
        this.files = resp.data.entries;
      });
    },
    getFiles(group) {
      this.loading = true;
      const options = {
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
      };

      return axios
        .post(
          "https://api.dropboxapi.com/2/files/list_folder",
          {
            path: group.id,
            recursive: false,
            include_media_info: true,
            include_deleted: false,
            include_has_explicit_shared_members: true,
            include_mounted_folders: true,
            // "shared_link": '',
            // "include_property_groups": '',
            include_non_downloadable_files: true,
          },
          options
        )
        .catch((e) => {
          this.$store.commit("setDropboxToken", null);
          if (e.response) {
            if (e.response.status == 400) {
              this.$store.commit("setDropboxToken", null);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open() {
      window.close();
      window.open(this.fullUrl);
    },
    move(source, destination) {
      console.log("source", source);
      console.log("destination:", destination);
      this.loading = true;
      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/files/move_v2",
        data: {
          from_path: source.path,
          to_path: destination.path + source.path,
          // allow_shared_folder: true,
          // autorename: false,
          // allow_ownership_transfer: true,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          console.log(resp);
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFile(file) {
      this.loading = true;

      axios({
        url: "https://api.dropboxapi.com/2/files/delete_v2",
        data: {
          path: file.path,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then(() => {
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );

          util.notify(1, "Deleted Successfully");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    renameFile(file, name) {
      this.loading = true;
      let newPath = file.path.replace(file.name, "") + name;
      axios({
        method: "POST",
        url: "https://api.dropboxapi.com/2/files/move_v2",
        data: {
          from_path: file.path,
          to_path: newPath,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.dropbox_token,
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          util.notify(1, "Successfully Renamed");
          this.fetchFiles(
            this.$store.state.stack[this.$store.state.stack.length - 1]
          );
        })
        .finally(() => {
          this.$store.commit("toggleRenameDialog", false);
          this.loading = false;
        });
    },
  },
  computed: {
    fullUrl() {
      return `${this.baseUrl}?client_id=${this.client_id}&response_type=${this.response_type}&redirect_uri=${this.redirect_uri}`;
    },
    computedFiles() {
      return this.files.map((f) => {
        let a = {
          id: f.id,
          folder: f[".tag"] == "folder" ? true : false,
          name: f.name,
          createdDateTime: new Date(),
          path: f.path_display,
          shared_folder_id: f.shared_folder_id ?? null,
          parent_shared_folder_id: f.parent_shared_folder_id ?? null,
          // thumbnails: f.thumbnails,
          thumbnail: null,
        };
        // if (f.thumbnails.length > 0) {
        //   a.thumbnail = f.thumbnails[0].large.url;
        // }
        return a;
      });
    },
  },
  watch: {
    "$store.state.dropbox_token": function(n) {
      localStorage.setItem("dropbox_token", n);
    },
  },
};
</script>
<style lang="scss">
.file-name {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
</style>
