import { render, staticRenderFns } from "./ProjectTree.vue?vue&type=template&id=85999e32&scoped=true&"
import script from "./ProjectTree.vue?vue&type=script&lang=js&"
export * from "./ProjectTree.vue?vue&type=script&lang=js&"
import style0 from "./ProjectTree.vue?vue&type=style&index=0&id=85999e32&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85999e32",
  null
  
)

export default component.exports