<template>
  <v-card>
    <v-card-title class="text-h5" style="word-break: break-word"
      >Confirm Action</v-card-title
    >
    <v-card-text class="text" style="font-size: 1.2rem; color:#000;"
      >Are you sure you want to do this?</v-card-text
    >
    <v-card-actions class="mt-3" style="padding-left: 24px">
      <v-btn
        color="#7a86ab"
        min-width="90px"
        class="button-color"
        @click="cancel"
        >No</v-btn
      >
      <v-btn
        color="#1d2b58"
        min-width="90px"
        class="button-color"
        @click="deleteResource"
        >Yes</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    resource: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteResource() {
      util
        .http({
          url: this.$props.resource + "/" + this.$props.id,
          method: "delete",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.$emit("delete-success", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>
