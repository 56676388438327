var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-boards",staticStyle:{"border-bottom":"2px solid #1d2b58"}},[_c('component-layout',{attrs:{"title":"Boards"},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],on:{"click":_vm.addBoard}},[_vm._v(" ADD ")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-5"},[(_vm.project)?_c('v-treeview',{attrs:{"open":_vm.initiallyOpen,"items":_vm.computedItems,"activatable":"","item-key":"id","open-on-click":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [('parent_id' in item && item.parent_id)?_c('v-icon',[_vm._v("mdi-calendar-check-outline")]):_c('v-icon',[_vm._v(" mdi-"+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto bg-btn",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((item.context_action),function(lItem,i){return _c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":function($event){return _vm.itemClicked(item, lItem)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s(lItem.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(lItem.text)}})],1)],1)}),1)],1)],1)]}}],null,false,3041046228),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showAddForm),callback:function ($$v) {_vm.showAddForm=$$v},expression:"showAddForm"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.project)?_c('CreateBoard',{attrs:{"project":_vm.project}}):_vm._e()],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }