var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-0"},[_c('component-layout',{attrs:{"title":_vm.project.name},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v("ADD")])],1),_c('v-list-item',[_c('v-list-item-title',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"click":_vm.editProject}},[_vm._v(" EDIT")])],1),_c('v-list-item',[_c('v-list-item-title',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v("DELETE")])],1)],1)],1)]},proxy:true}])},[_c('div',{staticClass:"my-10"},[_c('div',{staticClass:"row align-center"},[_c('v-icon',{staticClass:"primary-color mr-2"},[_vm._v("mdi-calendar-month")]),_c('div',[_c('span',{staticClass:"primary-color"},[_vm._v("Project Date")]),_c('h4',[_vm._v(_vm._s(_vm.project.start_date)+" to "+_vm._s(_vm.project.end_date))])])],1),_c('div',{staticClass:"row align-center mt-10"},[_c('div',{staticClass:"row"},[_c('v-icon',{staticClass:"primary-color mr-2"},[_vm._v("mdi-priority-high")]),_c('div',[_c('span',{staticClass:"primary-color"},[_vm._v("Priority")]),_c('h4',[_vm._v(_vm._s(_vm.project.priority))])])],1),_c('div',{staticClass:"row mt-0"},[_c('v-icon',{staticClass:"primary-color mr-2"},[_vm._v("mdi-list-status")]),_c('div',[_c('span',{staticClass:"primary-color"},[_vm._v("Status")]),_c('h4',[_vm._v(_vm._s(_vm.project.status))])])],1)]),_c('div',{staticClass:"row my-10"},[_c('span',{staticClass:"primary-color pb-2"},[_c('v-icon',{staticClass:"primary-color mr-2"},[_vm._v("mdi-account-group")]),_vm._v("Assigned Users:")],1),[_c('div',[_c('v-combobox',{attrs:{"items":_vm.project.assignees,"chips":"","label":"Your favorite hobbies","multiple":"","solo":"","disabled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected},on:{"click":select}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.user.full_name))]),_vm._v("  ")])]}}]),model:{value:(_vm.project.assignees),callback:function ($$v) {_vm.$set(_vm.project, "assignees", $$v)},expression:"project.assignees"}})],1)]],2)]),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showEditForm),callback:function ($$v) {_vm.showEditForm=$$v},expression:"showEditForm"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('CreateProject',{attrs:{"edit":_vm.project}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }