<template>
  <div>
    <action :edit="$props.edit"> </action>
  </div>
</template>
<script>
import action from "@/components/shared/action_buttons_setting.vue";
export default {
  components: { action },
  props: {
    edit: {
      type: Object,
    },
  },
};
</script>
